import styles from './styles.module.scss';
import { useTranslation, Trans } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import Button, {
  BUTTON_MODE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '@components/Button';
import React from 'react';

import { getCssVariable } from '@utils/index';

type IProps = {
  closeModal: () => void;
  lang: string;
};

function createDownloadLinkPDF(
  prefix: string,
  language: string,
  nameFile: string,
  pageNumber?: number
) {
  const splitLang = language ? language.split('-')[0] : 'en';
  return `/${prefix}-${nameFile}-${splitLang}.pdf${
    pageNumber ? '#page=' + pageNumber.toString() : ''
  }`;
}

export default function VoiceProblemModal({ closeModal, lang }: IProps) {
  const { t } = useTranslation('onBoarding');

  // function createDownloadLinkPDF(
  //   prefix: string,
  //   language: string,
  //   nameFile: string,
  //   atLastPage?: boolean
  // ) {
  //   const splitLang = language ? language.split('-')[0] : 'en';
  //   return `/${prefix}-${nameFile}-${splitLang}.pdf${
  //     atLastPage ? '#page=17' : ''
  //   }`;
  // }

  const contactUsFn = React.useCallback(() => {
    window.location.href =
      `mailto:${process.env.REACT_APP_FIREBASE_EMAIL_SENDER}` as string;
  }, []);

  return (
    <div className={styles.inner}>
      <div className={styles.closeBtn}>
        <Button
          type={BUTTON_TYPE.DEFAULT}
          mode={BUTTON_MODE.GHOST}
          size={BUTTON_SIZE.ONLY_ICON}
          leadingIcon={
            <FeatherIcon
              strokeWidth="2px"
              aria-hidden="true"
              color={getCssVariable('neutral600')}
              onClick={closeModal}
              icon="x"
              size="24"
            />
          }
        ></Button>
      </div>

      <div className={styles.container}>
        <div className={styles.icon}>
          <FeatherIcon
            strokeWidth="1px"
            icon="alert-triangle"
            color={'var(--yellow)'}
            size="54"
          />
        </div>
        <h3>{t('voiceProblemModal.title')}</h3>
        <p>
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Trans
              defaults={t('voiceProblemModal.description')}
              components={{
                RecordingGuidelines: (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={createDownloadLinkPDF(
                      'Recording',
                      lang,
                      'guidelines'
                    )}
                  ></a>
                ),
              }}
            />
          }
        </p>
      </div>
      <div className={styles.footerModal}>
        <Button
          type={BUTTON_TYPE.PRIMARY}
          mode={BUTTON_MODE.BASIC}
          onClick={closeModal}
          fullWidth
        >
          {t('voiceProblemModal.recordVoice')}
        </Button>

        <Button
          type={BUTTON_TYPE.PRIMARY}
          mode={BUTTON_MODE.GHOST}
          onClick={contactUsFn}
          fullWidth
        >
          {t('voiceProblemModal.contactUs')}
        </Button>
      </div>
    </div>
  );
}
