/* eslint-disable @typescript-eslint/no-unused-vars */
import Header from '@components/Header';
import LayoutWrapper from '@components/LayoutWrapper';
import Loader from '@components/Loader';

import SplitLayout from '@components/SplitLayout';

import { doc, getDoc } from 'firebase/firestore';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUser, useFirestore } from 'reactfire';

import { Helmet } from 'react-helmet';
import VoiceModelContentInternalTool from '@components/VoiceModelContentInternalTool';
import VoiceModelRightSideInternalTool from '@components/VoiceModelRightSideInternalTool';
import { ProgressPageBar } from '@components/ProgressPageBar';
import { toast, ToastContainer } from 'react-toastify';
import ToastModel from '@components/ToastModel';
import { ROUTES } from '@routes/routes';

export const gamificationPoints = [
  10, 50, 100, 150, 250, 350, 450, 562,
] as const;
export type RecordingStep = typeof gamificationPoints[number];

export const isGamificationPoint = (step: number): step is RecordingStep => {
  return gamificationPoints.includes(step as RecordingStep);
};

export default function RecordModel() {
  const { t } = useTranslation(['modelRecording']);
  const [currentLanguage, setCurrentLanguage] = React.useState('');
  // const [usersChooseMyVoice, setUsersChooseMyVoice] = React.useState<number>(0);

  const [recordModelStep, setRecordModelStep] = React.useState<number>(0);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const db = useFirestore();
  const { data: user } = useUser();

  const showToast = React.useCallback(() => {
    toast(<ToastModel step={recordModelStep} />, {
      data: {
        title: isGamificationPoint(recordModelStep)
          ? t(`modelRecording:script.msg-${recordModelStep}.title`, {
              breakPoint: recordModelStep.toString(),
            })
          : null,
      },
    });
  }, [recordModelStep, t]);

  const increaseModelStep = React.useCallback(() => {
    setRecordModelStep((currentStep) => currentStep + 1);
    if (gamificationPoints.includes(recordModelStep as RecordingStep))
      showToast();
  }, [recordModelStep, showToast]);

  const getUserData = React.useCallback(async () => {
    if (user) {
      const userRefPublic = doc(db, 'users', user?.uid);
      const docSnapPublic = await getDoc(userRefPublic);
      const voicesDataPublic = docSnapPublic?.data();

      if (docSnapPublic.exists()) {
        const userDataPublic = docSnapPublic.data();

        const userRecordModelStep = userDataPublic?.recordModelStep ?? 0;
        const isModelVoiceRecordingComplete =
          userDataPublic?.isScriptModelVoiceComplete;

        if (
          isModelVoiceRecordingComplete ||
          !voicesDataPublic?.enableRecording
        ) {
          navigate(ROUTES.BASE);
        } else {
          setIsLoading(false);
        }

        const currentLanguage = userDataPublic?.language;

        setCurrentLanguage(currentLanguage);

        setRecordModelStep(userRecordModelStep);
      } else {
        console.log('No such document!');
      }
    }
  }, [user, db, navigate]);

  React.useEffect(() => {
    getUserData();
  }, [getUserData]);

  return isLoading ||
    recordModelStep === Number(process.env.REACT_APP_FINAL_STEP) ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>{t('modelRecording:script.pageTitle')}</title>
        <meta
          name="description"
          content={t('modelRecording:script.descriptionPage')}
        />
      </Helmet>
      <Header
        destination={ROUTES.BASE}
        buttonText={t('modelRecording:script.header')}
        voiceModelStep={recordModelStep}
      />
      <ProgressPageBar
        voiceModelStep={recordModelStep}
        totalStep={Number(process.env.REACT_APP_FINAL_STEP) - 1}
      />
      <LayoutWrapper>
        <SplitLayout
          left={
            <VoiceModelContentInternalTool
              step={recordModelStep}
              currentLanguage={currentLanguage}
              setStep={increaseModelStep}
            />
          }
          right={
            <VoiceModelRightSideInternalTool
              step={recordModelStep}
              language={currentLanguage}
            />
          }
        />
      </LayoutWrapper>
      <ToastContainer
        position="bottom-left"
        autoClose={Number(process.env.REACT_APP_AUTO_CLOSE_TOAST_MS)}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={false}
        closeButton={true}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
    </>
  );
}
