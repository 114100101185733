import styles from './styles.module.scss';

export enum AVATAR_SIZE {
  DEFAULT = 'default',
  SMALL = 'small',
  BIG = 'big',
  EXTRA_SMALL = 'x-small',
  MEDIUM = 'medium',
}

type Props = {
  image: string;
  onClick?: () => void;
  size?: AVATAR_SIZE;
  name: string;
};

export default function Avatar({
  image,
  onClick,
  name,
  size = AVATAR_SIZE.DEFAULT,
}: Props) {
  const initials =
    name &&
    name.split(' ').reduce((result, item) => `${result}${item.charAt(0)}`, '');
  return (
    <div
      onClick={onClick}
      className={`${styles.avatarWrapper} ${styles[size]}`}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
    >
      {image ? (
        <img
          src={image}
          height={32}
          width={32}
          className={styles.avatar}
          alt={initials}
        />
      ) : (
        <span>{initials}</span>
      )}
    </div>
  );
}
