import * as React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import Avatar, { AVATAR_SIZE } from '@components/Avatar';
import FeatherIcon from 'feather-icons-react';
import Player from '@components/Player';
import { getCssVariable } from '@utils/index';
import { useUser } from 'reactfire';
import { useNavigate } from 'react-router-dom';
import { setDoc, doc } from 'firebase/firestore';
import { ROUTES } from '@routes/routes';
import Spinner from '@components/Spinner';

export type UserCardProps = {
  voiceType: string;
  describeYourself?: string;
  firstname: string;
  language: string;
  profilePic?: string;
  openModal?: () => void;
  urlRec: string;
  db: any;
  avatarPhoto: string;
  isChecked: boolean;
};

export default function UserCardPreview({
  firstname,
  describeYourself,
  voiceType,
  profilePic,
  language,
  avatarPhoto,
  urlRec,
  isChecked,
  db,
  openModal,
}: UserCardProps) {
  const { t } = useTranslation('library');
  const { data: user } = useUser();
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [currentSong, setCurrentSong] = React.useState<any>({ url: urlRec });

  function renderLanguage(lang: string) {
    switch (lang) {
      case 'en-EN':
        return t('userCard.language.en');
      case 'it-IT':
        return t('userCard.language.it');
      case 'es-ES':
        return t('userCard.language.es');
      case 'de-DE':
        return t('userCard.language.de');
      case 'fr-FR':
        return t('userCard.language.fr');
      default:
        return '';
    }
  }

  const audioElem = React.useRef<HTMLAudioElement>(null);

  React.useEffect(() => {
    if (isPlaying) {
      audioElem?.current?.play();
    } else {
      audioElem?.current?.pause();
    }
  }, [isPlaying]);

  const onPlaying = () => {
    if (audioElem && audioElem?.current && audioElem?.current?.duration) {
      const duration = audioElem?.current?.duration as any;
      const ct = audioElem?.current?.currentTime as any;

      setCurrentSong({
        ...currentSong,
        progress: (ct / duration) * 100,
        length: duration,
      });
    }
  };

  function translateVoiceTypeLabel(voice: string) {
    switch (voice) {
      case 'middle':
        return t('userCard.voiceType.middle');
      case 'young':
        return t('userCard.voiceType.young');
      case 'senior':
        return t('userCard.voiceType.senior');
      default:
        return voice;
    }
  }

  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  const handleOnRemoveURL = React.useCallback(async () => {
    try {
      if (user) {
        setIsLoading(true);

        // REMOVE FUNCTION HERE
        // CHANGE ON ONBOARDING STEP TO RECORDING
        // await updateDoc(doc(db, 'users', user.uid), {
        // URL_recording: deleteField(),
        // });
        await setDoc(
          doc(db, `users/${user.uid}/private`, 'donor'),
          {
            // URL_recording: deleteField(),
            onBoardingStep: 'script',
            isChangingRecording: true,
            recordingTool: '',
          },
          { merge: true }
        );
        setIsLoading(false);
        setTimeout(() => {
          navigate(ROUTES.ONBOARDING);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [db, user]);

  return (
    <div className={styles.container}>
      <div className={styles.dataContainer}>
        <div className={styles.userDataWrapper}>
          <div className={styles.avatarContainer}>
            <Avatar
              name={`${firstname}`}
              image={profilePic ? profilePic : avatarPhoto}
              size={AVATAR_SIZE.MEDIUM}
            />
          </div>
          <div className={styles.userData}>
            {firstname && <h4>{firstname}</h4>}
            <div className={styles.userInfo}>
              <FeatherIcon
                strokeWidth={'2px'}
                aria-hidden="true"
                icon="message-circle"
                size="18"
              />
              <span className={`small ${styles.voiceType}`}>
                {translateVoiceTypeLabel(voiceType)}
              </span>
              <span className={`small ${styles.separator}`}>·</span>

              <FeatherIcon
                strokeWidth={'2px'}
                aria-hidden="true"
                icon="globe"
                size="18"
              />
              <span className="small">{renderLanguage(language)}</span>
            </div>
          </div>

          <div className={styles.like}>
            {isLoading ? (
              <Spinner isSmall />
            ) : (
              <FeatherIcon
                strokeWidth={'2px'}
                aria-hidden="true"
                color={getCssVariable('neutral400')}
                fill={'transparent'}
                // onClick={openModal}
                onClick={handleOnRemoveURL}
                icon="edit-2"
                size="22"
              />
            )}
          </div>
        </div>
        <div>
          {describeYourself && <p className="small">{describeYourself}</p>}
        </div>
      </div>
      <div className={styles.footerCard}>
        <audio onTimeUpdate={onPlaying} ref={audioElem} src={urlRec} />
        <Player
          currentSong={currentSong}
          audioElem={audioElem}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
        />
      </div>
    </div>
  );
}
