import * as React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Input from '@components/Input';
import Button, { BUTTON_TYPE } from '@components/Button';
import { emailRegex } from '@utils/index';
import Spinner from '@components/Spinner';

type Props = {
  isSending: boolean;
  onSubmit: ({ email }: { email: string }) => void;
};

export default function PasswordResetForm({ isSending, onSubmit }: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onSubmit' });

  const [isDirty, setIsDirty] = React.useState<{
    email: boolean;
  }>({
    email: false,
  });

  const { t } = useTranslation('passwordReset');

  const { ref: emailRef, ...emailInputProps } = register('email', {
    required: t('errorsState.invalid.email'),
    pattern: {
      value: emailRegex,
      message: t('errorsState.invalid.email'),
    },
  });

  function handleOnSubmit({ email }: { email: string }) {
    setIsDirty({ email: email.length > 0 });
    onSubmit({ email });
  }

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(handleOnSubmit as any)} noValidate>
        <Input
          hasValue={isDirty?.email ?? false}
          label={t('labels.emailAddress')}
          type="email"
          placeholder={t('placeholder.emailAddress')}
          inputRef={emailRef}
          {...emailInputProps}
          error={errors?.email?.message}
        />

        <Button type={BUTTON_TYPE.PRIMARY} fullWidth>
          <div className={!isSending ? styles.label : styles.hide}>
            {t('labels.resetPassword')}
          </div>
          {isSending && (
            <div className={styles.spinnerContainer}>
              <Spinner isWhite isMid />
            </div>
          )}
        </Button>
      </form>
    </div>
  );
}
