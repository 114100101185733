import styles from './styles.module.scss';
import { formatMinutes, formatSeconds } from '@utils/format-time';
import { useEffect, useRef, useState } from 'react';
import { VoiceRecorderState } from '.';

export function RecordingTimer({
  recorderState,
}: {
  recorderState: VoiceRecorderState;
}) {
  const timerRef = useRef<number>();
  const [recordingSeconds, setRecordingSeconds] = useState(0);

  useEffect(() => {
    if (recorderState == 'recording')
      timerRef.current = window.setInterval(() => {
        setRecordingSeconds((seconds) => ++seconds);
      }, 1000);
    if (recorderState != 'recording' && recorderState != 'paused') {
      if (timerRef.current) clearInterval(timerRef.current);
      setRecordingSeconds(0);
    }
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, [recorderState, setRecordingSeconds]);

  return (
    <div className={styles.recordingTime}>
      <span>{formatMinutes(Math.floor(recordingSeconds / 60))}</span>
      <span>:</span>
      <span>{formatSeconds(recordingSeconds % 60)}</span>
    </div>
  );
}
