/* eslint-disable @typescript-eslint/no-unused-vars */
import Player from '@components/Player';
import ToastModel from '@components/ToastModel';
import {
  IMediaRecorder,
  MediaRecorder,
  register,
} from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import platform from 'platform';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';

import {
  CheckButton,
  DeleteButton,
  DownloadButton,
  PauseButton,
  ResumeButton,
  StartButton,
  StopButton,
} from './RecordingButtons';
import { RecordingTimer } from './RecordingTimer';
import styles from './styles.module.scss';
export type VoiceRecorderState =
  | 'inactive'
  | 'starting'
  | 'recording'
  | 'paused'
  | 'recorded'
  | 'confirmed'
  | 'error';

export type CurrentTrack = {
  length: number;
  progress: number;
  url?: string;
};

function getBlobFromChunks(chunks: BlobPart[]) {
  return new Blob(chunks, {
    type: 'audio/wav',
  });
}

export default function VoiceModelRecorderControls({
  recorderState,
  setRecorderState,
  onConfirmedRecording,
}: {
  onConfirmedRecording: (blob: Blob) => void;
  recorderState: VoiceRecorderState;
  setRecorderState: (vrs: VoiceRecorderState) => void;
}) {
  const { t } = useTranslation(['onBoarding', 'modelRecording']);
  const [isGivenConsense, setIsGivenConsense] = useState(false);
  const microphonePermissionRef = useRef(false);
  const mediaRecorderRef = useRef<IMediaRecorder>();
  const chunksRef = useRef<BlobPart[]>([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [trackData, setTrackData] = useState<CurrentTrack | null>({
    progress: 0,
    length: 0,
  });

  const showToast = React.useCallback(() => {
    toast(<ToastModel />, {
      data: {
        message: t('onBoarding:internalTool.microphoneGuide.message'),
        title: t('onBoarding:internalTool.microphoneGuide.title'),
        icon: 'mic',
        color: 'light-blue',
      },
    });
  }, [t]);

  useEffect(() => {
    async function registerMediaRecorder() {
      await register(await connect());
    }
    registerMediaRecorder();
  }, []);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: { noiseSuppression: true } })
      .then((stream) => {
        setIsGivenConsense(true);
        microphonePermissionRef.current = true;
        const recorder = new MediaRecorder(stream, {
          mimeType: 'audio/wav',
        });

        recorder.onstop = () => {
          const blob = getBlobFromChunks(chunksRef.current);
          const audioURL = URL.createObjectURL(blob);
          setTrackData(() => ({
            length: 0,
            progress: 0,
            url: audioURL,
          }));
        };

        recorder.ondataavailable = (e) => {
          chunksRef.current.push(e.data);
        };

        mediaRecorderRef.current = recorder;
      })
      .catch((e) => {
        console.error({ e });
        showToast();
        microphonePermissionRef.current = false;
        setIsGivenConsense(false);
      });
  }, [showToast, setRecorderState]);

  const currentBrowser = platform.name;

  const audioElem = React.useRef<HTMLAudioElement>(null);

  function handleStartRecording() {
    if (
      !!microphonePermissionRef.current &&
      mediaRecorderRef.current !== null
    ) {
      setRecorderState('starting');
      setTimeout(async () => {
        try {
          mediaRecorderRef.current?.start();
          setRecorderState('recording');
        } catch (e) {
          setRecorderState('inactive');
          console.error(e);
        }
      }, 3000);
    }
  }

  function handlePauseRecording() {
    setRecorderState('paused');
    mediaRecorderRef.current?.pause();
  }

  function handleStopRecording(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.preventDefault();
    if (mediaRecorderRef.current) {
      const recorderState = mediaRecorderRef.current.state;
      if (recorderState !== 'recording' && recorderState !== 'paused') return;
      mediaRecorderRef.current.stop();
      chunksRef.current = [];
      setRecorderState('recorded');
    }
  }

  function handleDeleteRecording() {
    if (!mediaRecorderRef.current) return;
    if (recorderState === 'recording') mediaRecorderRef.current.stop();
    chunksRef.current = [];
    setRecorderState('inactive');
  }

  function handleResumeRecording() {
    setRecorderState('recording');
    mediaRecorderRef.current?.resume();
  }

  async function handleConfirmedRecording() {
    setRecorderState('confirmed');

    if (!mediaRecorderRef.current) return;

    onConfirmedRecording(getBlobFromChunks(chunksRef.current));
    chunksRef.current = [];
  }

  const onPlaying = () => {
    if (audioElem && audioElem?.current && audioElem?.current?.duration) {
      const duration = audioElem.current.duration;
      const ct = audioElem.current.currentTime;
      setTrackData((track) => {
        return {
          ...track,
          progress: (ct / duration) * 100,
          length: duration,
        };
      });
    }
  };

  return (
    <>
      <div className={styles.controlsContainer}>
        {recorderState === 'recorded' && (
          <div
            className={`${styles.recordingsContainer} ${
              currentBrowser === 'Firefox'
                ? styles.isFirefox
                : currentBrowser === 'Safari'
                ? styles.isSafari
                : ''
            }`}
          >
            <div className={styles.recordingsList}>
              <>
                <audio
                  onTimeUpdate={onPlaying}
                  ref={audioElem}
                  src={trackData?.url}
                />
                <Player
                  currentSong={trackData}
                  audioElem={audioElem}
                  isPlaying={isPlaying}
                  setIsPlaying={setIsPlaying}
                />
              </>
            </div>
          </div>
        )}

        {['inactive', 'starting', 'recording', 'paused'].includes(
          recorderState
        ) && (
          <>
            <div className={styles.recorderDisplay}>
              <RecordingTimer recorderState={recorderState} />
            </div>
            <div className={styles.messageState}>
              <p>
                {recorderState === 'starting'
                  ? t('onBoarding:internalTool.startRecording')
                  : recorderState === 'recording' || recorderState === 'paused'
                  ? t('onBoarding:internalTool.onRecord')
                  : t('onBoarding:internalTool.onStart')}
              </p>
            </div>
          </>
        )}

        {recorderState === 'recorded' && (
          <div className={styles.messageState} style={{ marginTop: '40px' }}>
            <p>{t('onBoarding:internalTool.onStop')}</p>
          </div>
        )}
        <div className={styles.startButtonContainer}>
          {/* Left button */}
          {['recording', 'paused', 'recorded'].includes(recorderState) && (
            <DeleteButton onClick={handleDeleteRecording} />
          )}

          {/* Central button */}
          {['recording', 'paused'].includes(recorderState) && (
            <StopButton
              onClick={(e) => {
                handleStopRecording(e);
                setIsPlaying(false);
              }}
            />
          )}
          {['recorded'].includes(recorderState) && (
            <CheckButton onClick={handleConfirmedRecording} />
          )}
          {['starting'].includes(recorderState) && (
            <StartButton
              onClick={(e) => {
                e.preventDefault();
              }}
              isStarting={true}
            />
          )}
          {['inactive'].includes(recorderState) && (
            <StartButton onClick={handleStartRecording} isStarting={false} />
          )}

          {/* Right button */}
          {recorderState === 'recording' && (
            <PauseButton onClick={handlePauseRecording} />
          )}
          {recorderState === 'paused' && (
            <ResumeButton onClick={handleResumeRecording} />
          )}
          {recorderState === 'recorded' && (
            <a href={trackData?.url} download className={styles.downloadLink}>
              <DownloadButton />
            </a>
          )}
        </div>
      </div>
      <ToastContainer
        position="top-left"
        className={'mic-toast'}
        autoClose={isGivenConsense ? 1000 : false}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={false}
        closeButton={true}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
    </>
  );
}
