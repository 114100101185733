/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styles from './styles.module.scss';
import FeatherIcon from 'feather-icons-react';
import { getCssVariable } from '@utils/index';
import { doc, getDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import Button, { BUTTON_MODE, BUTTON_TYPE } from '@components/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@routes/routes';
type Props = {
  user: any;
  recordModelStep: number;
  isVoiceModelComplete: boolean;
  isAutodonate: boolean;
  isModelRecordingEnabled?: boolean;
};
export default function WhatsNext({
  isVoiceModelComplete,
  user,
  recordModelStep,
  isAutodonate,
  isModelRecordingEnabled,
}: Props) {
  const { t } = useTranslation('dashboard');
  const db = useFirestore();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [usersChooseMyVoice, setUsersChooseMyVoice] = React.useState<number>(0);

  // const [recordVoiceModelStep, setRecordVoiceModelStep] =
  //   React.useState<number>(recordModelStep);

  const getChosenVoicesData = React.useCallback(async () => {
    if (user) {
      setIsLoading(true);
      const chosenVoicesRefPublic = doc(db, 'chosenVoices', user?.uid);
      const docSnapPublic = await getDoc(chosenVoicesRefPublic);

      const voicesDataPublic = docSnapPublic?.data();
      const voicesNumber = voicesDataPublic?.hasChosenMyVoice;
      setUsersChooseMyVoice(voicesNumber ?? 0);
    }
  }, [user, db]);

  React.useEffect(() => {
    getChosenVoicesData();
  }, [getChosenVoicesData]);

  const goToRecordModel = React.useCallback(() => {
    recordModelStep
      ? navigate(ROUTES.RECORD_MODEL)
      : navigate(ROUTES.VOICE_MODEL);
  }, [navigate, recordModelStep]);

  const calculatePercentage = React.useCallback((step: number) => {
    return ((step * 100) / Number(process.env.REACT_APP_FINAL_STEP)).toFixed(1);
  }, []);

  const progressStyle = {
    width: calculatePercentage(recordModelStep) + '%',
  };

  return (
    <div className={styles.container}>
      {!isVoiceModelComplete ? (
        <>
          <div className={styles.step}>
            <div className={`${styles.icon} ${styles.icon1}`}>
              <FeatherIcon
                strokeWidth="2px"
                color={getCssVariable('white')}
                icon="check"
                size="20"
              />
            </div>
            <div className={styles.content}>
              <p className="sub-header">{t('whatsNext.step1')}</p>
            </div>
          </div>
          <div className={styles.step}>
            <div
              className={`${styles.icon} ${styles.icon2} ${
                isModelRecordingEnabled ? styles.icon2Selected : ''
              }`}
            >
              <FeatherIcon
                strokeWidth="2px"
                color={getCssVariable('white')}
                icon={isModelRecordingEnabled ? 'check' : 'list'}
                size="20"
              />
            </div>
            <div
              className={`${styles.content} ${
                isModelRecordingEnabled ? styles.done : styles.toDo
              }`}
            >
              <p
                style={{ margin: isAutodonate ? '-4px 0 0 0' : '4px 0' }}
                className="sub-header bolder"
              >
                {isAutodonate
                  ? t('whatsNext.step2.titleAutodonate')
                  : t('whatsNext.step2.title')}
              </p>
              {!isModelRecordingEnabled && (
                <>
                  <p className="extra-small">
                    {isAutodonate
                      ? t('whatsNext.step2.descriptionAutodonate')
                      : t('whatsNext.step2.description', {
                          name: user?.displayName?.split(' ')[0],
                        })}
                  </p>
                  {!isAutodonate && (
                    <p className={styles.counter}>
                      {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        <Trans
                          defaults={t('whatsNext.step2.count', {
                            counter: usersChooseMyVoice ?? 0,
                            patient:
                              usersChooseMyVoice !== 0 &&
                              usersChooseMyVoice === 1
                                ? t('whatsNext.step2.patient')
                                : t('whatsNext.step2.patients'),
                          })}
                          components={[<strong></strong>]}
                        />
                      }
                    </p>
                  )}
                </>
              )}
            </div>
          </div>

          <div className={styles.step}>
            <div
              className={`${styles.icon} ${styles.icon3} ${
                isModelRecordingEnabled ? styles.icon3Selected : ''
              }`}
            >
              <FeatherIcon
                strokeWidth="2px"
                color={
                  isModelRecordingEnabled
                    ? getCssVariable('white')
                    : getCssVariable('orange')
                }
                icon="mic"
                size="20"
              />
            </div>
            <div
              className={`${styles.content} ${styles.content3} ${
                isModelRecordingEnabled ? styles.toDo : styles.opacity
              }`}
            >
              <p className={'sub-header'}>
                {isAutodonate
                  ? t('whatsNext.step3.titleAutodonate')
                  : t('whatsNext.step3.title')}
              </p>
              {isModelRecordingEnabled && (
                <>
                  <p className="extra-small">
                    {isAutodonate
                      ? t('whatsNext.step3.descriptionAutodonate')
                      : t('whatsNext.step3.description')}
                  </p>
                  <div className={styles.progressCta}>
                    <Button
                      type={BUTTON_TYPE.PRIMARY}
                      mode={
                        recordModelStep
                          ? BUTTON_MODE.OUTLINE
                          : BUTTON_MODE.BASIC
                      }
                      onClick={goToRecordModel}
                    >
                      {recordModelStep
                        ? t('whatsNext.step3.continueCta')
                        : t('whatsNext.step3.cta')}
                    </Button>
                    {recordModelStep && (
                      <div className={styles.progress}>
                        <div className={styles.progressBar}>
                          <div
                            className={styles.insideProgressBar}
                            style={{ ...progressStyle }}
                          ></div>
                        </div>{' '}
                        <span>
                          {calculatePercentage(recordModelStep as number)} %
                        </span>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className={styles.step}>
            <div className={`${styles.icon} ${styles.icon4}`}>
              <FeatherIcon
                strokeWidth="2px"
                color={getCssVariable('orange')}
                icon="heart"
                size="20"
              />
            </div>
            <div className={`${styles.content} ${styles.opacity}`}>
              <p className="sub-header">
                {isAutodonate
                  ? t('whatsNext.step4Autodonate')
                  : t('whatsNext.step4')}
              </p>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.step}>
          <div
            className={`${styles.icon} ${styles.icon4} ${styles.icon4Selected}`}
          >
            <FeatherIcon
              strokeWidth="2px"
              color={getCssVariable('white')}
              icon="heart"
              size="20"
            />
          </div>
          <div className={`${styles.content} ${styles.toDo}`}>
            <p className="sub-header">
              {isAutodonate
                ? t('whatsNext.step5.titleAutodonate')
                : t('whatsNext.step5.title')}
            </p>
            <p className="extra-small">
              {isAutodonate
                ? t('whatsNext.step5.descriptionAutodonate')
                : t('whatsNext.step5.description')}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
