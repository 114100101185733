/* eslint-disable react/jsx-key */
import styles from './styles.module.scss';
interface IProps {
  checked?: boolean;
  children: React.ReactElement | React.ReactElement[];
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}
export default function ToggleSwitch({ checked, children, onChange }: IProps) {
  return (
    <div className={styles.container}>
      <label className={`${styles.control} ${styles.controlCheckbox}`}>
        <input type="checkbox" onChange={onChange} checked={checked} />
        {children}
        <div className={styles.controlIndicator}></div>
      </label>
    </div>
  );
}
