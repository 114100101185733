/* eslint-disable @typescript-eslint/no-unused-vars */
import Spinner from '@components/Spinner';
import VoiceModelRecorderControls, {
  VoiceRecorderState,
} from '@components/VoiceModelRecorderControls';
import VoiceProblemModal from '@components/VoiceProblemModal';
import { ROUTES } from '@routes/routes';
import { validateAudioFile, VALIDATION_AUDIO_RESPONSE } from '@services/api';
import { format } from 'date-fns';
import { getApp } from 'firebase/app';
import { doc, setDoc, updateDoc, getDoc, arrayUnion } from 'firebase/firestore';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
  UploadTaskSnapshot,
} from 'firebase/storage';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { useFirestore, useUser } from 'reactfire';
import { templateThankYou } from '../../emailTemplate/thankYou';
import styles from './styles.module.scss';
import useBrowserDetection from '@hooks/useBrowserDetection';

const convertNumberInStorage = (step: number) => {
  const stringStep = String(step);
  const pad = '0000';
  return pad.substring(0, pad.length - stringStep.length) + stringStep;
};

type Props = {
  step: number;
  setStep: () => void;
  currentLanguage: string;
};

export default function VoiceModelContentInternalTool({
  step,
  setStep,
  currentLanguage,
}: Props) {
  const { t } = useTranslation(['onBoarding', 'modelRecording', 'thankYou']);
  const { data: user } = useUser();
  const navigate = useNavigate();
  const db = useFirestore();
  const [uploadProgress, setUploadProgress] = useState<number>();
  const [recorderState, setRecorderState] =
    useState<VoiceRecorderState>('inactive');
  const firebaseApp = getApp();
  const storage = getStorage(
    firebaseApp,
    process.env.REACT_APP_FIREBASE_BUCKET_VOICE_MODEL
  );

  const storageRef = ref(
    storage,
    `users/${user?.uid}/script-${convertNumberInStorage(step ?? 1)}.wav`
  );
  const browser = useBrowserDetection();
  const handleRecordingCompleted = async (blob: Blob) => {
    const text = t(`modelRecording:script.step-${step.toString()}` as any);
    const formData = new FormData();
    formData.append('language', currentLanguage);
    formData.append('text', text);
    formData.append('file', blob);
    formData.append('step', 'script');

    const validationResult = await validateAudioFile(formData)
      .then((res) => res.validation)
      .catch(() => {
        setRecorderState('error');
      });

    if (
      validationResult === VALIDATION_AUDIO_RESPONSE.ACCEPT &&
      blob.size > 1024 * 50
    ) {
      setUploadProgress(0);
      const uploadTask = uploadBytesResumable(storageRef, blob);
      uploadTask.on(
        'state_changed',
        (snapshot: UploadTaskSnapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        () => {
          setRecorderState('error');
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async () => {
            const date = format(new Date(), 'yyyy-MM-dd--HH:mm:ss');
            if (setStep) {
              setStep();
            }
            try {
              await updateDoc(doc(db, 'users', user?.uid as any), {
                recordModelStep: step && step + 1,
                browserDetailsModel: arrayUnion({ step, details: browser }),
              });
              if (
                step &&
                step + 1 === Number(process.env.REACT_APP_FINAL_STEP)
              ) {
                await setDoc(
                  doc(db, 'users', user?.uid as any),
                  {
                    isScriptModelVoiceComplete: true,
                    browserDetailsModel: arrayUnion({ step, details: browser }),
                  },
                  { merge: true }
                );

                if (user == null) throw new Error('User is null');

                const userRefPublic = doc(db, 'users', user.uid);
                const docSnapPublic = await getDoc(userRefPublic);
                const userDataPublic = docSnapPublic.data();
                const isAutodonate = userDataPublic?.isAutodonate;
                const title = t(
                  `thankYou:email.${isAutodonate ? 'titleAutodonate' : 'title'}`
                );
                const body = t(
                  `thankYou:email.${isAutodonate ? 'bodyAutodonate' : 'body'}`,
                  {
                    displayName: user?.displayName,
                    id: user?.uid,
                  }
                );

                await setDoc(
                  doc(db, `mail/${user?.uid}/notifications`, date as any),
                  {
                    from: process.env.REACT_APP_FIREBASE_EMAIL_SENDER,
                    replyTo: user?.email,
                    cc: process.env.REACT_APP_FIREBASE_EMAIL_CC_FINISH_MODEL,
                    to: user?.email,
                    message: {
                      subject: title,
                      html: templateThankYou(body, title),
                    },
                  }
                );
                setTimeout(() => {
                  navigate(ROUTES.THANK_YOU_RECORD_MODEL);
                }, 1200);
              }
              setRecorderState('inactive');
            } catch (e) {
              console.error(e);
              setRecorderState('error');
            }
          });
        }
      );
    } else {
      setRecorderState('error');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.recorderContainer}>
        <Modal
          isOpen={recorderState == 'error'}
          onRequestClose={() => {
            setRecorderState('inactive');
          }}
          overlayClassName={styles.overlay}
          className={styles.modal}
        >
          <VoiceProblemModal
            lang={currentLanguage || 'en-EN'}
            closeModal={() => {
              setRecorderState('inactive');
            }}
          />
        </Modal>
        <VoiceModelRecorderControls
          onConfirmedRecording={handleRecordingCompleted}
          recorderState={recorderState}
          setRecorderState={setRecorderState}
        />
        {recorderState == 'confirmed' && (
          <div className={styles.spinnerContainer}>
            <p>
              {uploadProgress == null
                ? t('onBoarding:voiceProblemModal.isAnalyzing')
                : t('onBoarding:internalTool.onSubmit', {
                    progress: uploadProgress.toFixed() + '%',
                  })}
            </p>
            <Spinner isMid />{' '}
          </div>
        )}
        {recorderState == 'error' && (
          <div className={styles.spinnerContainer}>
            <p className={styles.error}>
              {t('onBoarding:voiceProblemModal.errorsState')}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
