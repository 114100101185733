/* eslint-disable react/jsx-key */
import styles from './styles.module.scss';
interface IProps {
  checked?: boolean;
  children: React.ReactElement | React.ReactElement[];
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}
export default function Checkbox({ checked, children, onChange }: IProps) {
  return (
    <label className={`${styles.control} ${styles.controlCheckbox}`}>
      {children}
      <input type="checkbox" onChange={onChange} checked={checked} />
      <div className={styles.controlIndicator}></div>
    </label>
  );
}
