/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-onchange */

import React from 'react';
import styles from './styles.module.scss';
export interface Option {
  value: string;
  label: string;
}

interface IProps {
  options: Option[];
  onChange(o: any): void;
  value: any;
}

export default function BorderlessSelect({ options, onChange, value }: IProps) {
  function onSelectChange(e: React.ChangeEvent<HTMLInputElement>) {
    onChange({ value: e.target.value });
  }

  return (
    <div>
      <select
        multiple={false}
        onChange={onSelectChange as any}
        defaultValue={value?.value}
        style={{
          width: 7.1 * value?.label.length + 36,
          backgroundColor: '#fff',
        }}
      >
        {options.map((el) => (
          <option key={el.value} value={el.value}>
            {el.label}
          </option>
        ))}
      </select>
    </div>
  );
}
