import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router';
import * as React from 'react';
import { ROUTES } from '@routes/routes';

export default function AuthPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { oobCode, mode, continueUrl }: any = queryString.parse(
    location.search
  ) ?? {
    oobCode: '',
  };

  const redirectURL = continueUrl
    ? continueUrl.split('/').filter(Boolean)[2]
    : '';

  const baseURL = continueUrl ? continueUrl.split('/').filter(Boolean)[1] : '';
  const url = `${window.location.protocol}//${window.location.host}${ROUTES.NEW_PASSWORD}`;

  React.useEffect(() => {
    if (mode === 'resetPassword') {
      if (oobCode && continueUrl) {
        // navigate(`/${redirectURL}?code=${oobCode}`);
        window.location.replace(`${continueUrl}?code=${oobCode}`);
        // when recoverEmail is done you have a link also for reset password without continueUrl
      } else if (oobCode && !continueUrl) {
        window.location.replace(`${url}?code=${oobCode}`);
      }
    }

    if (mode === 'recoverEmail') {
      if (oobCode) {
        navigate(`${ROUTES.RECOVER_EMAIL}?code=${oobCode}`);
        // window.location.replace(`${baseURL}/recover-email?code=${oobCode}`);
      }
    }
  }, [mode, navigate, oobCode, redirectURL, baseURL]);

  return null;
}
