import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import Button, { BUTTON_MODE, BUTTON_TYPE } from '@components/Button';
import Modal from 'react-modal';
import { getCssVariable } from '@utils/index';
import FeatherIcon from 'feather-icons-react';
interface IProps {
  onDismiss: () => void;
  title: string;
  children: any;
  onSetFilters: () => void;
  onRemoveFilters: () => void;
  isOpen: boolean;
  isActive: boolean;
}

Modal.setAppElement('div');

const customStyles = {
  content: {
    top: 0,
    overflow: 'hidden',
    left: 0,
    right: 'auto',
    backgroundColor: 'transparent',
    bottom: 'auto',
    padding: 0,
    borderRadius: 0,
    marginRight: '-50%',
    border: 0,
    width: '100%',
    maxHeight: '-webkit-fill-available',
    transform: 'translate3d(0, 0, 0)',
  },
};

export default function ModalFilter({
  title,
  onDismiss,
  onSetFilters,
  onRemoveFilters,
  children,
  isActive,
  isOpen,
}: IProps) {
  const { t } = useTranslation('library');

  function handleOnSetFilter() {
    onSetFilters();
    onDismiss();
  }

  function handleOnRemoveFilter() {
    onRemoveFilters();
    onDismiss();
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onDismiss}
      style={customStyles as any}
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <FeatherIcon
            strokeWidth={'2px'}
            aria-hidden="true"
            color={getCssVariable('neutral600')}
            fill={'var(--neutral600)'}
            onClick={onDismiss}
            icon="x"
            size="22"
          />

          <h5>{title}</h5>
        </div>
        <div className={styles.body}>
          <div className={styles.scroll}>{children}</div>
        </div>

        <div className={styles.footer}>
          <Button
            type={BUTTON_TYPE.PRIMARY}
            mode={BUTTON_MODE.BASIC}
            fullWidth
            onClick={handleOnSetFilter}
          >
            {t('filters.labels.showResults')}
          </Button>
          {isActive && (
            <Button
              fullWidth
              type={BUTTON_TYPE.PRIMARY}
              mode={BUTTON_MODE.GHOST}
              onClick={handleOnRemoveFilter}
            >
              {t('filters.labels.remove')}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
