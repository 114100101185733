import Header from '@components/Header';
import LayoutWrapper from '@components/LayoutWrapper';
import SignUpRightSide from '@components/SignUpRightSide';
import CompleteRegistrationForm, {
  User,
} from '@components/CompleteRegistrationForm';
import SplitLayout from '@components/SplitLayout';
import { ROUTES } from '@routes/routes';

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { getAuth } from 'firebase/auth';

import { useTranslation } from 'react-i18next';
import { useFirestore, useUser } from 'reactfire';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { Helmet } from 'react-helmet';
// import { format } from 'date-fns';

export enum SIGNUP_ERROR_TYPE {
  EMAIL = 'email',
  GENERIC = 'generic',
  PASSWORD = 'password',
}

export default function CompleteRegistration() {
  const navigate = useNavigate();
  const auth = getAuth();

  const { t } = useTranslation('signUp');
  const [error, setError] = React.useState<{ type: string; message: string }>({
    type: '',
    message: '',
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const db = useFirestore();
  const [step, setStep] = React.useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userType, setUserType] = React.useState<string>('');

  // const returnUrl = `${window.location.protocol}//${window.location.host}/sign-in`;
  const { data: user } = useUser();

  React.useEffect(() => {
    async function getUser() {
      if (user) {
        setIsLoading(true);
        const userRefPublic = doc(db, 'users', user?.uid);
        const docSnapPublic = await getDoc(userRefPublic);

        const userDataPublic = docSnapPublic.data();
        const userType = userDataPublic?.userType;
        setUserType(userType);
        if (userType === 'both' || userType === 'donor') {
          navigate(`${ROUTES.BASE}`);
        } else {
          setIsLoading(false);
        }
      }
    }
    getUser();
  }, [db, navigate, user]);

  const onSignUp = React.useCallback(
    async ({
      birthday,
      gender,
      voiceProficiency,
      voiceType,
      language,
      country,
      agreeTerms,
      agreeTerms2,
    }: User) => {
      try {
        const yearBirth = birthday?.split('-')[0];
        const age = yearBirth && new Date().getFullYear() - +yearBirth;

        await setDoc(
          doc(
            db,
            'users',
            auth?.currentUser && (auth?.currentUser?.uid as any)
          ),
          {
            language,
            describeYourself: '',
            userType: 'both',
            id: auth?.currentUser?.uid,
            gender,
            voiceType,
          },
          { merge: true }
        );

        await setDoc(
          doc(db, `users/${auth?.currentUser?.uid}/private`, 'donor'),
          {
            birthday,
            age,
            firstname: auth?.currentUser?.displayName?.split(' ')[0],
            lastname: auth?.currentUser?.displayName?.split(' ')[1],
            email: auth?.currentUser?.email,
            agreeTerms,
            agreeTerms2,
            country,
            gender,
            language,
            onBoardingStep: 'script',
            voiceProficiency,
            recordingTool: '',
            isChangingRecording: false,
          },
          { merge: true }
        );

        // const date = format(new Date(), 'yyyy-MM-dd--HH:mm:ss');
        // if (auth?.currentUser?.uid) {
        //   await setDoc(
        //     doc(
        //       db,
        //       `mail/${auth?.currentUser?.uid}/notifications`,
        //       date as any
        //     ),
        //     {
        //       from: process.env.REACT_APP_FIREBASE_EMAIL_SENDER,
        //       replyTo: auth?.currentUser?.email,
        //       to: process.env.REACT_APP_FIREBASE_EMAIL_RECIPIENT,
        //       message: {
        //         subject: `New DONOR with name ${user?.displayName}`,
        //         text: `User ${auth?.currentUser?.displayName?.split(' ')[0]} ${
        //           auth?.currentUser?.displayName?.split(' ')[1]
        //         } with ID ${auth?.currentUser?.uid} and email ${
        //           auth?.currentUser?.email
        //         }, has just signed up as DONOR`,
        //       },
        //     }
        //   );
        // }

        navigate(`${ROUTES.ONBOARDING}`);
      } catch (err: any) {
        if (err instanceof Error) {
          setError({ type: SIGNUP_ERROR_TYPE.GENERIC, message: err?.message });
        }
        if (err?.code === 'auth/email-already-in-use') {
          setError({
            type: SIGNUP_ERROR_TYPE.EMAIL,
            message: t('form.errorsState.general.emailAlreadyInUse'),
          });
          setStep(1);
          setTimeout(() => {
            setError({ type: '', message: '' });
            navigate(ROUTES.SIGN_IN);
          }, 5000);
        } else if (err?.code === 'auth/invalid-email') {
          setError({
            type: SIGNUP_ERROR_TYPE.EMAIL,
            message: t('form.errorsState.general.invalidEmail'),
          });
          setStep(1);
          setTimeout(() => {
            setError({ type: '', message: '' });
          }, 2000);
        } else if (err.code === 'auth/weak-password') {
          setStep(1);
          setError({
            type: SIGNUP_ERROR_TYPE.PASSWORD,
            message: t('form.errorsState.general.weakPassword'),
          });
          setTimeout(() => {
            setError({ type: '', message: '' });
          }, 2000);
        } else {
          setError(err?.message);
          setTimeout(() => {
            setError({ type: '', message: '' });
          }, 2000);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [auth, db]
  );

  function onSubmitForm(user: User): void {
    setIsLoading(true);
    onSignUp(user);
  }

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
        <meta name="description" content={t('descriptionPage')} />
      </Helmet>

      <Header
        destination={ROUTES.SIGN_IN}
        buttonText={!auth?.currentUser ? t('header.signIn') : ''}
      />

      <LayoutWrapper>
        <SplitLayout
          userType={userType}
          left={
            <>
              <CompleteRegistrationForm
                onSubmit={onSubmitForm}
                isLoading={isLoading}
                step={step}
                setStep={setStep}
                error={error}
              />
              {error?.message.length ? (
                <p className={styles.errorMessage}>{error?.message}</p>
              ) : null}
            </>
          }
          right={<SignUpRightSide />}
        />
      </LayoutWrapper>
    </>
  );
}
