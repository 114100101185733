/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ROUTES } from '@routes/routes';
import { AuthProvider, useFirebaseApp, useInitPerformance } from 'reactfire';
import { Routes, Route, Navigate } from 'react-router-dom';
import Loader from '@components/Loader';
import { AuthWrapper } from '@components/AuthWrapper';
import { initializeFirestore } from 'firebase/firestore';
import { FirestoreProvider, useInitFirestore } from 'reactfire';
import SignUp from '@pages/SignUp';
import SignIn from '@pages/SignIn';
import Onboarding from '@pages/Onboarding';
import Dashboard from '@pages/Dashboard';
import PasswordReset from '@pages/PasswordReset';
import 'react-toastify/dist/ReactToastify.css';
import NewPassword from '@pages/NewPassword';
import { VoiceUploaderProvider } from '@contexts/VoiceUploaderContext';
import AuthPage from '@pages/Auth';
import ThankYou from '@pages/ThankYou';
import { ProfileImageProvider } from '@contexts/ProfileImageContext';
import { ModalFaqProvider } from '@contexts/ModalFaqContext';
import RecoverEmail from '@pages/RecoverEmail';
// import PrivacyPolicy from '@pages/PrivacyPolicy';
import CookieBanner from '@components/CookieBanner';
import PageNotFound from '@pages/404';
import CompleteRegistration from '@pages/CompleteRegistration';
import Library from '@pages/Library';
import VoiceModel from '@pages/VoiceModel';
import RecordModel from '@pages/RecordModel';
import { useTranslation } from 'react-i18next';
import ThankYouRecordModel from '@pages/ThankYouRecordModel';
import Autodonate from '@pages/Autodonate';

const App = () => {
  const firebaseApp = useFirebaseApp();

  const auth = getAuth(firebaseApp);
  const { i18n } = useTranslation();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLogged, setIsLogged] = React.useState<boolean>(false);
  useInitPerformance(async (firebaseApp) => {
    const { getPerformance } = await import('firebase/performance');
    return getPerformance(firebaseApp);
  });

  const { status, data: firestoreInstance } = useInitFirestore(
    async (firebaseApp) => {
      const db = initializeFirestore(firebaseApp, {});
      return db;
    }
  );

  onAuthStateChanged(auth, (user) => {
    setIsLoading(false);
    if (user) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
  });

  return status === 'loading' || isLoading ? (
    <Loader />
  ) : (
    <>
      <AuthProvider sdk={auth}>
        <FirestoreProvider sdk={firestoreInstance}>
          <ProfileImageProvider>
            <ModalFaqProvider>
              <Routes>
                <Route path="*" element={<PageNotFound />} />
                <Route
                  path={ROUTES.SIGN_IN}
                  element={
                    <SignIn />
                    // isLogged ? <Navigate to={ROUTES.BASE} /> : <SignIn />
                  }
                />
                <Route path={ROUTES.SIGN_UP} element={<SignUp />} />
                <Route path={ROUTES.AUTODONATE} element={<Autodonate />} />
                <Route
                  path={ROUTES.FINISH_REGISTRATION}
                  element={<CompleteRegistration />}
                />
                <Route
                  path={ROUTES.RESET_PASSWORD}
                  element={<PasswordReset />}
                />
                {/* <Route
                  path={ROUTES.PRIVACY_POLICY}
                  element={<PrivacyPolicy />}
                /> */}
                <Route path={ROUTES.NEW_PASSWORD} element={<NewPassword />} />
                <Route path={ROUTES.RECOVER_EMAIL} element={<RecoverEmail />} />

                <Route
                  path={ROUTES.VOICE_MODEL}
                  element={
                    <AuthWrapper fallback={<Navigate to={ROUTES.SIGN_IN} />}>
                      <VoiceUploaderProvider>
                        <VoiceModel />
                      </VoiceUploaderProvider>
                    </AuthWrapper>
                  }
                />

                <Route
                  path={ROUTES.RECORD_MODEL}
                  element={
                    <AuthWrapper fallback={<Navigate to={ROUTES.SIGN_IN} />}>
                      <VoiceUploaderProvider>
                        <RecordModel />
                      </VoiceUploaderProvider>
                    </AuthWrapper>
                  }
                />
                <Route
                  path={ROUTES.ONBOARDING}
                  element={
                    <AuthWrapper fallback={<Navigate to={ROUTES.SIGN_IN} />}>
                      <VoiceUploaderProvider>
                        <Onboarding />
                      </VoiceUploaderProvider>
                    </AuthWrapper>
                  }
                />

                <Route
                  path={ROUTES.BASE}
                  element={
                    <AuthWrapper fallback={<Navigate to={ROUTES.SIGN_UP} />}>
                      <VoiceUploaderProvider>
                        <Dashboard initialLang={i18n.language} />
                      </VoiceUploaderProvider>
                    </AuthWrapper>
                  }
                />

                <Route
                  path={ROUTES.LIBRARY}
                  element={
                    <AuthWrapper fallback={<Navigate to={ROUTES.SIGN_UP} />}>
                      <VoiceUploaderProvider>
                        <Library />
                      </VoiceUploaderProvider>
                    </AuthWrapper>
                  }
                />
                <Route
                  path={ROUTES.THANK_YOU}
                  element={
                    <AuthWrapper fallback={<Navigate to={ROUTES.SIGN_IN} />}>
                      <VoiceUploaderProvider>
                        <ThankYou />
                      </VoiceUploaderProvider>
                    </AuthWrapper>
                  }
                />

                <Route
                  path={ROUTES.THANK_YOU_RECORD_MODEL}
                  element={
                    <AuthWrapper fallback={<Navigate to={ROUTES.SIGN_IN} />}>
                      <VoiceUploaderProvider>
                        <ThankYouRecordModel />
                      </VoiceUploaderProvider>
                    </AuthWrapper>
                  }
                />

                <Route path={ROUTES.AUTH} element={<AuthPage />} />
              </Routes>

              <CookieBanner />
            </ModalFaqProvider>
          </ProfileImageProvider>
        </FirestoreProvider>
      </AuthProvider>
    </>
  );
};
export default App;
