import * as React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useAuth, useFirestore, useUser } from 'reactfire';
import Logo from '@components/Logo';
import styles from './styles.module.scss';
import Avatar, { AVATAR_SIZE } from '@components/Avatar';
import { ProfileImageContext } from '@contexts/ProfileImageContext';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import FaqModal from '@components/FaqModal';

import Button, { BUTTON_MODE, BUTTON_TYPE } from '@components/Button';
import { useNavigate } from 'react-router-dom';
import { ModalFaqContext } from '@contexts/ModalFaqContext';
import { ROUTES } from '@routes/routes';
import { doc, getDoc } from 'firebase/firestore';
import { getApp } from 'firebase/app';

interface Props {
  buttonText: string;
  destination?: any;
  voiceModelStep?: number;
}

const Header = ({ buttonText, destination, voiceModelStep }: Props) => {
  const { data: user } = useUser();
  const db = useFirestore();

  const { imageURLProfile, setImageURLProfile } =
    React.useContext(ProfileImageContext);
  const [avatarPhoto, setAvatarPhoto] = React.useState('');
  const firebaseApp = getApp();

  const storage = getStorage(
    firebaseApp,
    process.env.REACT_APP_FIREBASE_BUCKET
  );

  const navigate = useNavigate();
  const storageRef = ref(
    storage,
    `users/${user?.uid}/profilePicture/profile.jpg`
  );

  const getUserData = React.useCallback(async () => {
    if (user) {
      const usersRefPublic = doc(db, 'users', user?.uid);
      const docSnapPublic = await getDoc(usersRefPublic);

      const userDataPublic = docSnapPublic?.data();

      const avatarURL = userDataPublic?.avatar_photo;

      setAvatarPhoto(avatarURL);
    }
  }, [user, db]);

  React.useEffect(() => {
    getUserData();
  }, [getUserData]);

  function goToDashboard() {
    navigate(ROUTES.BASE);
  }

  const { showFaq, setShowFaq, setIsLinkClicked } =
    React.useContext(ModalFaqContext);

  React.useEffect(() => {
    getDownloadURL(storageRef)
      .then((url) => {
        if (url) {
          setImageURLProfile(url);
        } else {
          setImageURLProfile('');
        }
      })
      .catch((error) => {
        if (error.code === 'storage/object-not-found') {
          setImageURLProfile('');
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function closeModal() {
    setShowFaq(false);
    setIsLinkClicked(false);
  }

  return (
    <>
      <FaqModal showFaq={showFaq} closeModal={closeModal} />
      <header className={styles.header}>
        <span className={styles.logo}>
          <Logo full={true} />
        </span>

        <div className={styles.navigation}>
          {buttonText && (
            <Button
              onClick={() => {
                if (destination !== 'help') {
                  navigate(destination);
                } else {
                  setShowFaq(true);
                }
              }}
              type={BUTTON_TYPE.PRIMARY}
              mode={
                destination === ROUTES.BASE
                  ? BUTTON_MODE.OUTLINE
                  : BUTTON_MODE.GHOST
              }
            >
              {buttonText}
            </Button>
          )}
          {user && destination !== ROUTES.BASE && (
            <Avatar
              onClick={goToDashboard}
              name={user?.displayName ?? ''}
              image={
                imageURLProfile
                  ? imageURLProfile
                  : user?.photoURL
                  ? user?.photoURL
                  : avatarPhoto
              }
              size={AVATAR_SIZE.DEFAULT}
            />
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
