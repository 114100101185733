import Loader from '@components/Loader';
import { ROUTES } from '@routes/routes';

import { doc, getDoc } from 'firebase/firestore';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFirestore, useUser } from 'reactfire';
import styles from './styles.module.scss';
type IProps = {
  left: React.ReactNode;
  right: React.ReactNode;
  userType?: string;
};

const SplitLayout = ({ userType, left, right }: IProps) => {
  const [isRecordingToolSelectedInternal, setIsRecordingToolSelectedInternal] =
    React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const db = useFirestore();
  const { data: user } = useUser();

  const navigate = useNavigate();
  const getUserData = React.useCallback(async () => {
    if (user) {
      setIsLoading(true);
      const userRef = doc(db, `users/${user.uid}/private`, 'donor');
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();

        setIsRecordingToolSelectedInternal(
          !userData?.URL_recording || userData.isChangingRecording
        );
        setIsLoading(false);
      } else {
        console.log('No such document!');
      }
    }
  }, [user, db]);

  React.useEffect(() => {
    getUserData();
    if (userType !== 'donor' && userType !== 'both') {
      setIsLoading(false);
    } else {
      navigate(ROUTES.BASE);
    }
  }, [getUserData, navigate, userType]);

  return isLoading ? (
    <Loader />
  ) : (
    <main
      className={
        (isRecordingToolSelectedInternal &&
          window.location.pathname === '/record-your-voice') ||
        window.location.pathname === '/record-model'
          ? `${styles.splitLayout} ${styles.internalRecWrap}`
          : styles.splitLayout
      }
    >
      <section className={styles.wrapper}>
        <div className={styles.content}>{left}</div>
      </section>
      <section
        className={
          (isRecordingToolSelectedInternal &&
            window.location.pathname === '/record-your-voice') ||
          window.location.pathname === '/record-model'
            ? `${styles.wrapper} ${styles.internalRecWrap}`
            : styles.wrapper
        }
      >
        <div className={styles.content}>{right}</div>
      </section>
    </main>
  );
};

export default SplitLayout;
