/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next';
import React from 'react';

type IProps = {
  lang: string;
  step: number;
};

export default function ScriptVoiceModel({ lang, step }: IProps): any {
  const { t } = useTranslation(['modelRecording']);
  const { i18n } = useTranslation();

  const changeLanguage = React.useCallback(
    (lng: string) => {
      i18n.changeLanguage(lng);
    },
    [i18n]
  );

  React.useEffect(() => {
    if (lang === 'en-EN') {
      changeLanguage('en');
    } else if (lang === 'de-DE') {
      changeLanguage('de');
    } else if (lang === 'fr-FR') {
      changeLanguage('fr');
    } else if (lang === 'es-ES') {
      changeLanguage('es');
    } else if (lang === 'it-IT') {
      changeLanguage('it');
    }
  }, [lang, changeLanguage, i18n.language]);

  return <p>{t(`modelRecording:script.step-${step}` as any)}</p>;

  // if (lang === 'en-EN') {
  //   switch (step) {
  //     case 1:
  //       return (
  //         <p>
  //           Nel mezzo del cammin di nostra vita mi ritrovai per una selva
  //           oscura. 1 EN
  //         </p>
  //       );
  //     case 2:
  //       return (
  //         <p>
  //           Nel mezzo del cammin di nostra vita mi ritrovai per una selva
  //           oscura. 2 EN
  //         </p>
  //       );
  //     case 3:
  //       return (
  //         <p>
  //           Nel mezzo del cammin di nostra vita mi ritrovai per una selva
  //           oscura. 3 EN
  //         </p>
  //       );
  //     case 4:
  //       return (
  //         <p>
  //           Nel mezzo del cammin di nostra vita mi ritrovai per una selva
  //           oscura. 4 EN
  //         </p>
  //       );
  //   }
  // }
  // if (lang === 'de-DE') {
  //   switch (step) {
  //     case 1:
  //       return <p>step 1 DE</p>;
  //     case 2:
  //       return <p>step 2 DE</p>;
  //     case 3:
  //       return <p>step 3 DE</p>;
  //   }
  // }
  // if (lang === 'es-ES') {
  //   switch (step) {
  //     case 1:
  //       return <p>step 1 ES</p>;
  //     case 2:
  //       return <p>step 2 ES</p>;
  //     case 3:
  //       return <p>step 3 ES</p>;
  //   }
  // }
  // if (lang === 'it-IT') {
  //   switch (step) {
  //     case 1:
  //       return <p>step 1 IT</p>;
  //     case 2:
  //       return <p>step 2 IT</p>;
  //     case 3:
  //       return <p>step 3 IT</p>;
  //   }
  // }
  // if (lang === 'fr-FR') {
  //   switch (step) {
  //     case 1:
  //       return <p>step 1 FR</p>;
  //     case 2:
  //       return <p>step 2 FR</p>;
  //     case 3:
  //       return <p>step 3 FR</p>;
  //   }
  // }
}
