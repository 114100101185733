import { WithChildren } from '@utils/types/helpers';
import * as React from 'react';
import { MouseEventHandler } from 'react';
import styles from './styles.module.scss';

export enum BUTTON_MODE {
  BASIC = 'basic',
  OUTLINE = 'outline',
  GHOST = 'ghost',
}
export enum BUTTON_TYPE {
  PRIMARY = 'primary',
  ERROR = 'error',
  DEFAULT = 'default',
  RECORDING = 'recording',
  DONE = 'done',
}
export enum BUTTON_SIZE {
  TEXT = 'text',
  WITH_ICON = 'with-icon',
  ONLY_ICON = 'only-icon',
}

type Props = WithChildren<{
  leadingIcon?: React.ReactNode;
  type?: BUTTON_TYPE;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  size?: BUTTON_SIZE;
  style?: any;
  fullWidth?: boolean;
  disabled?: boolean;
  mode?: BUTTON_MODE;
  showCounter?: boolean;
}>;

export default function Button({
  children,
  disabled = false,
  onClick,
  fullWidth = false,
  leadingIcon,
  showCounter = false,
  style = {},
  type = BUTTON_TYPE.PRIMARY,
  size = BUTTON_SIZE.TEXT,
  mode = BUTTON_MODE.BASIC,
}: Props) {
  const LeadingIcon = leadingIcon ? leadingIcon : null;
  const [counter, setCounter] = React.useState(3);

  React.useEffect(() => {
    if (showCounter) {
      const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

      setTimeout(() => {
        setCounter(3);
      }, 3000);
      return () => clearInterval(timer as any);
    }
  }, [counter, showCounter]);

  return (
    <button
      disabled={disabled ? true : false}
      onClick={onClick}
      style={style}
      className={`${styles.container} ${fullWidth ? styles.fullWidth : ''} ${
        styles[type]
      } ${styles[size]} ${styles[mode]} 
 
      `}
    >
      {showCounter && (
        <div className={styles.counter}>
          <h3>{counter}</h3>
        </div>
      )}

      {LeadingIcon && !showCounter && (
        <div className={styles.leadingIcon}>{leadingIcon}</div>
      )}
      <span>{children}</span>
    </button>
  );
}
