export const ROUTES = {
  BASE: '/',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  ONBOARDING: '/record-your-voice',
  RESET_PASSWORD: '/reset-password',
  NEW_PASSWORD: '/new-password',
  RECOVER_EMAIL: '/recover-email',
  EMAIL_VERIFICATION: '/verify-email',
  AUTH: '/auth',
  THANK_YOU: '/thank-you',
  THANK_YOU_RECORD_MODEL: '/thank-you-voice-model',
  PRIVACY_POLICY: '/privacy-policy',
  CHOOSE_TOOL: '/recording-tool',
  RECORD_MODEL: '/record-model',
  VOICE_MODEL: '/voice-model',
  FINISH_REGISTRATION: '/complete-registration',
  LIBRARY: '/library',
  AUTODONATE: '/autodonate',
};
