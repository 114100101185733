import styles from './styles.module.scss';

type Props = {
  title: string;
  underlineWords?: string;
  description?: string;
  leadingIcon?: React.ReactNode;
  vector?: any;
};

export default function HeaderOnBoardingWrapper({
  underlineWords = '',
  title = '',
  description = '',
  leadingIcon,
  vector,
}: Props) {
  return (
    <div className={styles.container}>
      {leadingIcon}
      <h3 style={{ marginBottom: description ? '16px' : '40px' }}>
        {underlineWords && (
          <span>
            {underlineWords}{' '}
            {vector && <img className={styles.vector} src={vector} />}
          </span>
        )}

        {title}
      </h3>
      {description && <p className={styles.subTitle}>{description}</p>}
    </div>
  );
}
