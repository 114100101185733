/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import platform from 'platform';
import ScriptVoiceModel from '@components/ScriptVoiceModel';
import Spinner from '@components/Spinner';

type IProps = {
  language: string;
  step: number;
};
const VoiceModelRightSideInternalTool = ({ language, step }: IProps) => {
  const { t } = useTranslation(['modelRecording']);
  const [currentBrowser, setCurrentBrowser] = React.useState('');

  React.useEffect(() => {
    if (platform.name) {
      setCurrentBrowser(platform?.name);
    }
  }, []);

  return !step ? (
    <Spinner />
  ) : (
    <>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.script}>
            <div className={styles.innerContent}>
              <p className={styles.count}>
                {step}{' '}
                {t('modelRecording:script.of', {
                  total: Number(process.env.REACT_APP_FINAL_STEP) - 1,
                })}
              </p>
              <div className={styles.script}>
                <ScriptVoiceModel lang={language} step={step} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VoiceModelRightSideInternalTool;
