/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { ProfileImageContext } from '@contexts/ProfileImageContext';
import DeleteRecordingPrompt from '@components/DeleteRecordingPrompt';
import UserCardPreview from '@components/UserCardPreview';
import { Link } from 'react-router-dom';
import { ROUTES } from '@routes/routes';
import ToggleSwitch from '@components/ToggleSwitch';
import { updateDoc, doc } from 'firebase/firestore';

type Props = {
  user: any;
  db: any;
};

export default function VoicePreview({ user, db }: Props) {
  // const [showPrompt, setShowPrompt] = React.useState(false);
  const { imageURLProfile } = React.useContext(ProfileImageContext);
  const [isChecked, setIsChecked] = React.useState<boolean>(
    user?.isHiddenFromLibrary ? false : true
  );
  const { t } = useTranslation('dashboard');

  React.useEffect(() => {
    setIsChecked(user?.isHiddenFromLibrary);
  }, [user?.isHiddenFromLibrary]);

  // function closeModal() {
  //   setShowPrompt(false);
  // }

  // function openModal() {
  //   setShowPrompt(true);
  // }

  Modal.setAppElement('div');

  async function handleOnChange(e: any) {
    setIsChecked(e.target.checked ? false : true);
    await updateDoc(doc(db, 'users', user.id), {
      isHiddenFromLibrary: e.target.checked ? false : true,
    });
  }
  return (
    <>
      {/* <Modal
        isOpen={showPrompt}
        onRequestClose={closeModal}
        overlayClassName={styles.overlay}
        className={styles.modal}
      >
        <DeleteRecordingPrompt db={db} closeModal={closeModal} />
      </Modal> */}

      {user?.userType === 'both' && (
        <ToggleSwitch onChange={handleOnChange} checked={!isChecked}>
          <p className={styles.title}>
            {t('voicePreview.hideFromLibrary.title')}
          </p>
          <p className={styles.hideSubtitle}>
            {t('voicePreview.hideFromLibrary.subTitle')}
          </p>
        </ToggleSwitch>
      )}

      <UserCardPreview
        isChecked={isChecked}
        firstname={user?.firstname}
        language={user?.language}
        describeYourself={user?.describeYourself}
        voiceType={user?.voiceType}
        profilePic={imageURLProfile}
        urlRec={user?.URL_recording}
        db={db}
        avatarPhoto={user?.avatar_photo}
        // openModal={openModal}
      />
      {!isChecked && (
        <p className={`${styles.subtitles} small`}>
          {t('voicePreview.subTitle')}{' '}
          <Link target={'_blank'} to={ROUTES.LIBRARY}>
            {t('voicePreview.linkToLibrary')}
          </Link>
        </p>
      )}
    </>
  );
}
