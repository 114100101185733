import { ToastContentProps } from 'react-toastify';
import styles from './styles.module.scss';

import { isGamificationPoint, RecordingStep } from '@pages/RecordModel';
import { getCssVariable } from '@utils/index';
import FeatherIcon from 'feather-icons-react';

type Props = {
  step?: number;
};

const stepsMap: Record<
  RecordingStep,
  { background: string; color: string; icon: string }
> = {
  10: {
    background: 'purple-light2',
    color: 'purple',
    icon: 'flag',
  },
  50: {
    background: 'purple-light2',
    color: 'purple',
    icon: 'flag',
  },
  100: {
    background: 'purple-light2',
    color: 'purple',
    icon: 'flag',
  },
  150: {
    background: 'blue-light2',
    color: 'blue',
    icon: 'thumbs-up',
  },
  250: {
    background: 'blue-light2',
    color: 'blue',
    icon: 'thumbs-up',
  },
  350: {
    background: 'blue-light2',
    color: 'blue',
    icon: 'thumbs-up',
  },
  450: {
    background: 'yellow-light',
    color: 'yellow',
    icon: 'flag',
  },
  562: {
    background: 'yellow-light',
    color: 'yellow',
    icon: 'award',
  },
};

export default function ToastModel({
  step,
  toastProps,
}: Partial<ToastContentProps> & Props) {
  const data = (toastProps?.data as any) ?? {};

  return (
    <div className={styles.container}>
      <div
        className={styles.icon}
        style={{
          backgroundColor:
            step && isGamificationPoint(step)
              ? getCssVariable(stepsMap[step].background)
              : '',
        }}
      >
        <FeatherIcon
          strokeWidth="2px"
          aria-hidden="true"
          color={
            step && isGamificationPoint(step)
              ? getCssVariable(stepsMap[step].color)
              : data?.color
              ? getCssVariable(data?.color)
              : ''
          }
          icon={
            step && isGamificationPoint(step)
              ? stepsMap[step].icon
              : data?.icon
              ? data?.icon
              : 'flag'
          }
          size="20"
        />
      </div>
      <div className={styles.content}>
        <p className={styles.title}>{data?.title}</p>
        {data?.message && (
          <div className={styles.body}>
            <p className={styles.message}>{data?.message}</p>
          </div>
        )}
      </div>
    </div>
  );
}
