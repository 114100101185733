/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Input from '@components/Input';
import { emailRegex } from '@utils/index';
import Button, {
  BUTTON_MODE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '@components/Button';
import Spinner from '@components/Spinner';
import { Link } from 'react-router-dom';
import { ROUTES } from '@routes/routes';
import HeaderOnBoardingWrapper from '@components/HeaderOnBoardingWrapper';

type Props = {
  isLoading: boolean;
  onSubmit: ({ email, password }: { email: string; password: string }) => void;
};

export default function SignInForm({ isLoading, onSubmit }: Props) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: 'onSubmit' });

  const passValue = watch('password');
  const emailValue = watch('email');

  const [isDirty, setIsDirty] = React.useState<{
    email: boolean;
    password: boolean;
  }>({
    email: false,
    password: false,
  });

  const { t } = useTranslation('signIn');

  const { ref: emailRef, ...emailInputProps } = register('email', {
    required: t('form.errorsState.invalid.email'),
    pattern: {
      value: emailRegex,
      message: t('form.errorsState.invalid.email'),
    },
  });

  const { ref: passwordRef, ...passwordInputProps } = register('password', {
    required: t('form.errorsState.invalid.password'),
  });

  function handleOnSubmit({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) {
    setIsDirty({ email: email.length > 0, password: password.length > 0 });
    onSubmit({ email, password });
  }

  return (
    <div className={styles.container}>
      <HeaderOnBoardingWrapper title={t('titlePage')} />
      <form onSubmit={handleSubmit(handleOnSubmit as any)} noValidate>
        <Input
          hasValue={isDirty?.email ?? false}
          label={t('form.labels.emailAddress')}
          type="email"
          placeholder={t('form.placeholder.emailAddress')}
          inputRef={emailRef}
          {...emailInputProps}
          error={errors?.email?.message}
        />

        <Input
          hasValue={isDirty?.password ?? false}
          label={t('form.labels.password')}
          type="password"
          placeholder={t('form.placeholder.password')}
          inputRef={passwordRef}
          {...passwordInputProps}
          error={errors?.password?.message}
        />
        <p className={styles.resetWrapper}>
          <Link to={ROUTES.RESET_PASSWORD}>{t('form.forgotPassword')}</Link>
        </p>
        <Button
          type={BUTTON_TYPE.PRIMARY}
          mode={BUTTON_MODE.BASIC}
          size={BUTTON_SIZE.WITH_ICON}
          fullWidth
          disabled={!passValue || !emailValue}
        >
          {isLoading ? <Spinner isWhite isMid /> : t('form.labels.signIn')}
        </Button>
        <p className={styles.goToSignUpWrap}>
          {t('form.dontHaveAccount')}{' '}
          <Link to={ROUTES.SIGN_UP}>
            <span className={styles.signUpToDonate}>
              {t('form.signUpToDonate')}
            </span>
            <span className={styles.signUp}>{t('form.signUp')}</span>
          </Link>
        </p>
      </form>
    </div>
  );
}
