/* eslint-disable @typescript-eslint/no-unused-vars */
import Header from '@components/Header';
import { ROUTES } from '@routes/routes';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SplitLayout from '@components/SplitLayout';
import SignInRightSide from '@components/SignInRightSide';
import SignInForm from '@components/SignInForm';
import LayoutWrapper from '@components/LayoutWrapper';
import styles from './styles.module.scss';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useFirestore, useUser } from 'reactfire';
import { Helmet } from 'react-helmet';

export default function SignIn() {
  const auth = getAuth();
  const db = useFirestore();
  const { t } = useTranslation('signIn');
  const navigate = useNavigate();
  const [error, setError] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [userType, setUserType] = React.useState('');
  const { data: user } = useUser();

  const onSignIn = React.useCallback(
    async (email: string, password: string) => {
      try {
        const { user } = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );

        const userRefPublic = doc(db, 'users', user?.uid);
        const docSnapPublic = await getDoc(userRefPublic);

        const userDataPublic = docSnapPublic.data();
        const userType = userDataPublic?.userType;
        setUserType(userType);
        if (userType === 'donor' || userType === 'both') {
          const userRef = doc(db, `users/${user.uid}/private`, 'donor');
          const docSnap = await getDoc(userRef);

          if (docSnap.exists() && docSnapPublic.exists()) {
            const userData = docSnap.data();
            if (userData?.onBoardingStep) {
              const currentStep = userData.onBoardingStep;

              if (currentStep === 'done' || currentStep === 'submit') {
                navigate(`${ROUTES.BASE}`);
              }
            } else {
              navigate(`${ROUTES.ONBOARDING}`);
            }
          } else {
            console.log('No such document!');
            navigate(`${ROUTES.ONBOARDING}`);
          }
        } else {
          navigate(ROUTES.FINISH_REGISTRATION);
        }
      } catch (err) {
        if (err instanceof Error) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            err?.code === 'auth/user-not-found'
          ) {
            setError(t('form.errorsState.general.userNotFound'));
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
          } else if (err.code === 'auth/wrong-password') {
            setError(t('form.errorsState.general.wrongPassword'));
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
          } else if (err.code === 'auth/too-many-requests') {
            setError(t('form.errorsState.general.tooManyRequest'));
          }
        }
      } finally {
        setIsLoading(false);
      }
    },
    [auth, db, navigate]
  );

  function onSubmitForm({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): void {
    setIsLoading(true);
    onSignIn(email, password);
  }

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
        <meta name="description" content={t('descriptionPage')} />
      </Helmet>
      <Header destination={ROUTES.SIGN_UP} buttonText={t('header.signUp')} />

      <LayoutWrapper>
        <SplitLayout
          left={
            <>
              <SignInForm onSubmit={onSubmitForm} isLoading={isLoading} />
              {error.length ? (
                <p className={styles.errorMessage}>{error}</p>
              ) : null}
            </>
          }
          right={<SignInRightSide />}
        />
      </LayoutWrapper>
    </>
  );
}
