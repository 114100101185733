/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import Button, {
  BUTTON_MODE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '@components/Button';

import { getCssVariable } from '@utils/index';
import FaqItem from '@components/FaqItem';
import { ModalFaqContext } from '@contexts/ModalFaqContext';

type IProps = {
  closeModal: any;
  titleFaq: string;
};

export default function FaqModalContent({ closeModal, titleFaq }: IProps) {
  const { t } = useTranslation('faq');

  const {
    faqNumberOpened,
    setFaqNumberOpened,
    isLinkClicked,
    setIsLinkClicked,
  } = React.useContext(ModalFaqContext);

  function openFaqItem(faqNumber: number) {
    setFaqNumberOpened(faqNumber);
    setIsLinkClicked(true);
  }

  function closeModalItem() {
    setIsLinkClicked(false);
  }
  return (
    <>
      {isLinkClicked ? (
        <FaqItem
          closeModal={closeModalItem}
          faqOpened={faqNumberOpened}
          closeFirstModal={closeModal}
        />
      ) : (
        <div className={styles.inner}>
          <div className={styles.header}>
            <h4>{titleFaq && titleFaq}</h4>
            <Button
              type={BUTTON_TYPE.DEFAULT}
              mode={BUTTON_MODE.GHOST}
              size={BUTTON_SIZE.ONLY_ICON}
              leadingIcon={
                <FeatherIcon
                  strokeWidth="2px"
                  aria-hidden="true"
                  color={getCssVariable('neutral600')}
                  onClick={closeModal}
                  icon="x"
                  size="24"
                />
              }
            ></Button>
          </div>
          <div className={styles.container}>
            <ul className={styles.faqContainer}>
              <li onClick={() => openFaqItem(1)} className={styles.listItem}>
                {t('faq1.titleLink')}
              </li>
              <li onClick={() => openFaqItem(2)} className={styles.listItem}>
                {t('faq2.titleLink')}
              </li>
              <li onClick={() => openFaqItem(3)} className={styles.listItem}>
                {t('faq3.titleLink')}
              </li>
              <li onClick={() => openFaqItem(4)} className={styles.listItem}>
                {t('faq4.titleLink')}
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}
