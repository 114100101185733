import styles from './styles.module.scss';
import { concatStrings } from '@utils/index';

type Props = {
  isGrey?: boolean;
  isWhite?: boolean;
  isSmall?: boolean;
  isMid?: boolean;
};
const Spinner = ({
  isGrey = false,
  isWhite = false,
  isSmall = false,
  isMid = false,
}: Props) => {
  const defaultClassName = `${styles.spinner}`;
  const colorClassName = `${
    isGrey
      ? styles.spinnerGrey
      : isWhite
      ? styles.spinnerWhite
      : styles.spinnerBlue
  }`;
  const sizeClassName = `${
    isSmall ? styles.spinnerSmall : isMid ? styles.spinnerMid : ''
  }`;
  const spinnerClassName = concatStrings([
    defaultClassName,
    colorClassName,
    sizeClassName,
  ]);

  return (
    <div className={spinnerClassName}>
      <div className={styles.spinner__image} />
    </div>
  );
};

export default Spinner;
