/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Input from '@components/Input';
import { emailRegex } from '@utils/index';
import Button, { BUTTON_MODE, BUTTON_TYPE } from '@components/Button';
import Spinner from '@components/Spinner';
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { updateProfile } from 'firebase/auth';
import Avatar, { AVATAR_SIZE } from '@components/Avatar';
import FeatherIcon from 'feather-icons-react';
import Modal from 'react-modal';

import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  deleteObject,
} from 'firebase/storage';
import { ProfileImageContext } from '@contexts/ProfileImageContext';
import UserPrompt from '@components/UserPrompt';
import { getApp } from 'firebase/app';

export type User = {
  firstName: string;
  lastName: string;
  email: string;
  describeYourself: string;
};

type Dirty = {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  describeYourself: boolean;
};

type Props = {
  user: any;
  db: any;
  auth: any;
  userDescription?: string;
  avatarPhoto: string;
  userType: string;
};

export default function PersonalDetails({
  userDescription,
  user,
  db,
  auth,
  userType,
  avatarPhoto,
}: Props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setErrors] = React.useState('');
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [changeInfoProfileSuccessfully, setChangeInfoProfileSuccessfully] =
    React.useState(false);
  const {
    register,
    handleSubmit,

    setError,
    watch,
    formState: { errors },
  } = useForm({ mode: 'onSubmit' });
  const { setImageURLProfile, imageURLProfile } =
    React.useContext(ProfileImageContext);
  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const email = watch('email');
  const describeYourself = watch('describeYourself');

  const firebaseApp = getApp();

  const storage = getStorage(
    firebaseApp,
    process.env.REACT_APP_FIREBASE_BUCKET
  );

  const [isDirty, setIsDirty] = React.useState<Dirty>({
    firstName: false,
    lastName: false,
    email: false,
    describeYourself: false,
  });

  const { t } = useTranslation('dashboard');

  const { ref: firstNameRef, ...firstNameInputProps } = register('firstName', {
    required: t('personalDetails.form.errorsState.required.firstname'),
  });

  const { ref: lastNameRef, ...lastNameInputProps } = register('lastName', {
    required: t('personalDetails.form.errorsState.required.lastname'),
  });

  const { ref: describeYourselfRef, ...describeYourselfInputProps } = register(
    'describeYourself',
    {
      required: false,
    }
  );

  const { ref: emailRef, ...emailInputProps } = register('email', {
    required: t('personalDetails.form.errorsState.invalid.email'),
    pattern: {
      value: emailRegex,
      message: t('personalDetails.form.errorsState.invalid.email'),
    },
  });

  function handleOnSubmit({
    firstName,
    lastName,
    email,
    describeYourself,
  }: User) {
    setIsDirty({
      email: email.length > 0,
      describeYourself: describeYourself.length > 0,
      firstName: firstName.length > 0,
      lastName: lastName.length > 0,
    } as Dirty);
    setIsLoading(true);
    onUpdate({
      email,
      describeYourself,
      firstName,
      lastName,
    } as User);
  }

  function handleKeyDown(e: any) {
    // Reset field height
    e.target.style.height = 'inherit';

    // Get the computed styles for the element
    const computed = window.getComputedStyle(e.target);

    // Calculate the height
    const height =
      parseInt(computed.getPropertyValue('border-top-width'), 10) +
      parseInt(computed.getPropertyValue('padding-top'), 10) +
      e.target.scrollHeight +
      parseInt(computed.getPropertyValue('padding-bottom'), 10) +
      parseInt(computed.getPropertyValue('border-bottom-width'), 10);

    e.target.style.height = `${height}px`;
  }

  const onUpdate = React.useCallback(
    async ({ firstName, lastName, email, describeYourself }: User) => {
      try {
        if (user) {
          if (user?.email !== email) {
            setShowPrompt(true);
          } else {
            setIsLoading(true);
            await updateProfile(auth.currentUser, {
              displayName: `${firstName} ${lastName}`,
            });

            await updateDoc(doc(db, 'users', user.uid), {
              firstname: firstName,
              describeYourself,
            });

            await setDoc(
              doc(db, `users/${user.uid}/private`, 'donor'),
              {
                firstname: firstName,
                lastname: lastName,
                email,
              },
              { merge: true }
            );

            if (userType === 'both') {
              await setDoc(
                doc(db, `users/${user.uid}/private`, 'library'),
                {
                  firstname: firstName,
                  lastname: lastName,
                  email,
                },
                { merge: true }
              );
            }

            setChangeInfoProfileSuccessfully(true);
            setTimeout(() => {
              setChangeInfoProfileSuccessfully(false);
            }, 3000);
            setIsLoading(false);
          }
        }
      } catch (err: any) {
        if (err instanceof Error) {
          setErrors(err.message);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [user, db, auth.currentUser]
  );

  React.useEffect(() => {
    if (errors?.message) {
      setError(errors.type, { type: 'manual', message: errors.message });
    }
  }, [errors, setError]);

  const profileImageRef = ref(
    storage,
    `users/${user?.uid}/profilePicture/profile.jpg`
  );

  React.useEffect(() => {
    getDownloadURL(profileImageRef)
      .then(async (url) => {
        if (url) {
          setImageURLProfile(url);
          await setDoc(
            doc(db, 'users', user.uid),
            {
              profile_photo: url,
            },
            { merge: true }
          );
        } else {
          setImageURLProfile('');
          await setDoc(
            doc(db, 'users', user.uid),
            {
              profile_photo: '',
            },
            { merge: true }
          );
        }
      })
      .catch(async (error) => {
        if (error.code === 'storage/object-not-found') {
          setImageURLProfile('');
          await setDoc(
            doc(db, 'users', user.uid),
            {
              profile_photo: '',
            },
            { merge: true }
          );
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChangeImageProfile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files[0]) {
      uploadBytes(profileImageRef, e.target.files[0]).then((snapshot) => {
        getDownloadURL(snapshot.ref).then(async (url) => {
          setImageURLProfile(url);
          await setDoc(
            doc(db, 'users', user.uid),
            {
              profile_photo: url,
            },
            { merge: true }
          );
        });
      });
    }
  }

  function removeImage() {
    deleteObject(profileImageRef)
      .then(async () => {
        setImageURLProfile(null);
        await setDoc(
          doc(db, 'users', user.uid),
          {
            profile_photo: '',
          },
          { merge: true }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function closeModal() {
    setShowPrompt(false);
  }

  Modal.setAppElement('div');

  return (
    <>
      {/* {showPrompt && ( */}
      <Modal
        isOpen={showPrompt}
        onRequestClose={closeModal}
        overlayClassName={styles.overlay}
        className={styles.modal}
      >
        <UserPrompt
          db={db}
          user={user}
          firstName={firstName}
          lastName={lastName}
          newEmail={email}
          describeYourself={describeYourself}
          currentAuthUser={auth?.currentUser}
          closeModal={closeModal}
        />
      </Modal>
      {/* )} */}
      <div className={styles.container}>
        <div className={styles.profilePicture}>
          <p>{t('personalDetails.profilePicture')}</p>

          <div className={styles.avatar}>
            <Avatar
              name={user?.displayName ?? ''}
              image={
                imageURLProfile
                  ? imageURLProfile
                  : user?.photoURL
                  ? user?.photoURL
                  : avatarPhoto
              }
              size={AVATAR_SIZE.DEFAULT}
            />
            <input
              id={'uploadPhoto'}
              type="file"
              accept=".jpg, .jpeg, .png"
              onChange={handleChangeImageProfile}
              className={styles.inputField}
            />

            <label htmlFor={'uploadPhoto'} className={styles.labelBtn}>
              {t('personalDetails.changePhoto')}
            </label>
            <FeatherIcon
              className={
                !imageURLProfile ? styles.disableIcon : styles.trashIcon
              }
              strokeWidth="2px"
              color={imageURLProfile ? 'var(--red)' : 'var(--red-light)'}
              icon="trash-2"
              onClick={removeImage}
              size="20"
            />
          </div>
        </div>
        <form onSubmit={handleSubmit(handleOnSubmit as any)}>
          <fieldset className={styles.isHalf}>
            <Input
              hasValue={isDirty?.firstName ?? false}
              label={t('personalDetails.form.labels.firstName')}
              type="text"
              placeholder={t('personalDetails.form.placeholder.firstName')}
              inputRef={firstNameRef}
              defaultValue={user?.displayName.split(' ')[0] ?? ''}
              {...firstNameInputProps}
              error={errors?.firstName?.message}
            />

            <Input
              hasValue={isDirty?.lastName ?? false}
              label={t('personalDetails.form.labels.lastName')}
              type="text"
              defaultValue={user?.displayName.split(' ')[1] ?? ''}
              placeholder={t('personalDetails.form.placeholder.lastName')}
              inputRef={lastNameRef}
              {...lastNameInputProps}
              error={errors?.lastName?.message}
            />
          </fieldset>
          <fieldset>
            <Input
              hasValue={isDirty?.email ?? false}
              label={t('personalDetails.form.labels.emailAddress')}
              type="email"
              placeholder={t('personalDetails.form.placeholder.emailAddress')}
              inputRef={emailRef}
              defaultValue={user?.email ?? ''}
              {...emailInputProps}
              error={errors?.email?.message}
            />
            {/* <p className={`extra-small ${styles.emailInfo}`}>
              {t('personalDetails.form.labels.emailInfo')}
            </p> */}

            <label className={styles.labelBtn}>
              {t('personalDetails.form.labels.describeYourself')}
            </label>
            <div className={styles.wrapper}>
              <div className={styles.inputWrapper}>
                <textarea
                  maxLength={200}
                  onKeyDown={handleKeyDown}
                  defaultValue={userDescription ?? ''}
                  ref={describeYourselfRef}
                  placeholder={t(
                    'personalDetails.form.placeholder.describeYourself'
                  )}
                  {...describeYourselfInputProps}
                />
                <div className={styles.wrapperHelper}>
                  <div className={styles.onFocusBorder} />
                </div>
              </div>
            </div>
          </fieldset>
          <div className={styles.buttonUpdate}>
            <Button
              type={BUTTON_TYPE.PRIMARY}
              mode={BUTTON_MODE.OUTLINE}
              disabled={!firstName || !lastName || !email}
            >
              {isLoading ? (
                <Spinner isMid />
              ) : (
                t('personalDetails.form.labels.update')
              )}
            </Button>
            {error.length ? (
              <p className={styles.errorMessage}>{error}</p>
            ) : null}
            {changeInfoProfileSuccessfully && (
              <p className={styles.success}>
                {t('personalDetails.form.success.profileChanged')}
              </p>
            )}
          </div>
        </form>
      </div>
    </>
  );
}
