/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@routes/routes';
import Header from '@components/Header';
import LayoutWrapper from '@components/LayoutWrapper';
import SplitLayout from '@components/SplitLayout';
import OnBoardingRightSide from '@components/OnBoardingRightSide';
import recordModelRight from '@assets/img/recordModelRight.png';
import Button, {
  BUTTON_MODE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '@components/Button';
import FeatherIcon from 'feather-icons-react';
import { getCssVariable } from '@utils/index';
import SuccessMessageOnBoarding from '@components/SuccessMessageOnBoarding';
import { useFirestore, useUser } from 'reactfire';
// import { doc, getDoc } from 'firebase/firestore';
import Loader from '@components/Loader';
import styles from './styles.module.scss';
import { Helmet } from 'react-helmet';

export default function ThankYouRecordModel() {
  const { t } = useTranslation('thankYou');
  const navigate = useNavigate();
  const db = useFirestore();
  const { data: user } = useUser();
  // const [isLoading, setIsLoading] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  function setDoneAndGoToDashboard() {
    navigate(ROUTES.BASE);
  }

  // const updateUserData = React.useCallback(async () => {
  //   if (user) {
  //     await setDoc(
  //       doc(db, 'users', user.uid),
  //       {
  //         isScriptModelVoiceComplete: true,
  //       },
  //       { merge: true }
  //     );
  //   }
  // }, [db, user]);

  // const getUserData = React.useCallback(async () => {
  //   if (user) {
  //     const userRef = doc(db, 'users', user?.uid);
  //     const docSnap = await getDoc(userRef);
  //     if (docSnap.exists()) {
  //       const userData = docSnap.data();
  //       const isScriptModelVoiceComplete = userData?.isScriptModelVoiceComplete;

  //       if (isScriptModelVoiceComplete) {
  //         navigate(`${ROUTES.BASE}`);
  //       } else {
  //         setIsLoading(false);
  //       }
  //     } else {
  //       console.log('No such document!');
  //     }
  //   }
  // }, [user, db, navigate]);

  // React.useEffect(() => {
  //   getUserData();
  // }, [getUserData]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
        <meta name="description" content={t('descriptionPage')} />
      </Helmet>
      <Header buttonText={t('header')} />

      <LayoutWrapper>
        <SplitLayout
          left={
            <div className={styles.container}>
              <SuccessMessageOnBoarding
                title={t('voiceModel.title', {
                  name: user?.displayName?.split(' ')[0],
                })}
                message={t('voiceModel.description')}
                leadingIcon={
                  <Button
                    style={{
                      backgroundColor: getCssVariable('green-light'),
                      cursor: 'default',
                    }}
                    type={BUTTON_TYPE.RECORDING}
                    size={BUTTON_SIZE.ONLY_ICON}
                    leadingIcon={
                      <FeatherIcon
                        strokeWidth="1px"
                        icon="check"
                        color={'var(--green)'}
                        size="40"
                      />
                    }
                  ></Button>
                }
              >
                <Button
                  onClick={setDoneAndGoToDashboard}
                  type={BUTTON_TYPE.PRIMARY}
                  mode={BUTTON_MODE.OUTLINE}
                >
                  {t('voiceModel.backToDashboard')}
                </Button>
              </SuccessMessageOnBoarding>
            </div>
          }
          right={<OnBoardingRightSide urlImage={recordModelRight} />}
        />
      </LayoutWrapper>
    </>
  );
}
