type IProps = {
  lang: string;
};

export const renderScriptOnboardingForValidation = (
  lang: string,
  name: string
) => {
  switch (lang) {
    case 'en-EN':
      return `The voice is one of human beings' most versatile instruments. The human voice is one of the most sophisticated vehicles of expression. The quality of each person's voice affects their life, more than is commonly believed Hello, I am ${name} and if you want you can use my voice to communicate with others. You can use my voice in all situations, both to express joy and worry, to let people know when you're happy, or when something makes
          you angry. For example: Today is a beautiful day, look at the beautiful sun! Yesterday, as soon as I heard that bad news, I immediately started crying.Is it possible that I always have to remind you to tidy up in the kitchen?`;
    case 'it-IT':
      return `La voce è uno degli strumenti più versatili degli esseri umani. La voce umana, è un veicolo d’espressione tra i più sofisticati in assoluto.La qualità della voce di ogni persona ne influenza la vita, più di quanto non si creda comunemente. Ciao, io sono ${name} e se vorrai potrai usare la mia voce per comunicare con gli altri. Potrai usare la mia voce in tutte le situazioni, sia per esprimere gioia che preoccupazione, per far sapere quando sei felice, o quando qualcosa ti fà arrabbiare. Ad esempio: Oggi è proprio una bellissima giornata, guarda che bel sole! Ieri appena ho sentito di quella brutta notizia, ho subito iniziato a piangere. È mai possibile che debba sempre ricordarti io, di fare ordine in cucina?`;
    case 'es-ES':
      return `La voz es uno de los instrumentos más versátiles del ser humano. La voz humana es uno de los vehículos de expresión más sofisticados. La calidad de la voz de cada persona influye en su vida más de lo que comúnmente se cree. Hola, soy ${name} y si quieres puedes usar mi voz para comunicarte con los demás. Podrás usar mi voz en todas las situaciones, ya sea para expresar alegría o preocupación, para hacer saber cuándo estás feliz o cuándo algo te enfada. Por ejemplo: Hoy hace un día precioso, ¡mira qué sol hace! Ayer, en cuanto me enteré de esa mala noticia, me puse a llorar. ¿Será posible que siempre tenga que recordarte que pongas orden en la cocina?`;

    case 'de-DE':
      return `Die Stimme ist eines der vielseitigsten Instrumente des Menschen. Die menschliche Stimme ist eines der ausgeklügeltsten Ausdrucksmittel überhaupt. Die Qualität der Stimme eines jeden Menschen beeinflusst sein Leben mehr, als allgemein angenommen wird. Hallo, ich bin ${name} und wenn Sie möchten, können Sie meine Stimme verwenden, um mit anderen zu kommunizieren. Sie können meine Stimme in allen Situationen benutzen, sei es, um Freude oder Besorgnis auszudrücken, um andere wissen zu lassen, wenn Sie sich freuen, oder wenn Sie etwas wütend macht. Zum Beispiel: Heute ist ein wunderschöner Tag, schau' wie schön die Sonne scheint! Als ich gestern von dieser schlechten Nachricht hörte, fing ich sofort an zu weinen. Kann es sein, dass ich dich immer daran erinnern muss, die Küche aufzuräumen?`;
    case 'fr-FR':
      return `La voix est l’un des instruments les plus polyvalents de l’être humain.La voix humaine est l’un des moyens d’expression les plus sophistiqués. La qualité de la voix de chaque personne influence sa vie et ce, bien plus qu’on ne le croit. Bonjour, je suis ${name} et si vous le souhaitez, vous pouvez utiliser ma voix pour communiquer avec les autres. Vous pouvez utiliser ma voix dans toutes les situations, que ce soit pour exprimer de la joie ou de l’inquiétude, pour montrer que vous êtes heureux ou que quelque chose vous met en colère. Par exemple: Aujourd’hui est une si belle journée,regardez comme il fait beau ! Hier, dès que j’ai entendu parler de cette mauvaise nouvelle, j’ai immédiatement commencé à pleurer. Ce n’est pas possible, pourquoi dois-je toujours te rappeler de ranger la cuisine?`;
    default:
      return <p></p>;
  }
};

export default function ScriptInternalTool({ lang }: IProps): any {
  switch (lang) {
    case 'en-EN':
      return (
        <p>
          The voice is one of human beings' most versatile instruments.
          <br /> The human voice is one of the most sophisticated vehicles of
          expression.
          <br /> The quality of each person's voice affects their life, more
          than is commonly believed. <br /> Hello, I am _______ and if you want
          you can use my voice to communicate with others.
          <br /> You can use my voice in all situations, both to express joy and
          worry, to let people know when you're happy, or when something makes
          you angry.
          <br />
          <br /> For example:
          <br /> 😊 Today is a beautiful day, look at the beautiful sun! 😊
          <br /> 😔 Yesterday, as soon as I heard that bad news, I immediately
          started crying. 😔 <br /> 😡 Is it possible that I always have to
          remind you to tidy up in the kitchen? 😡
        </p>
      );
    case 'it-IT':
      return (
        <p>
          La voce è uno degli strumenti più versatili degli esseri umani.
          <br />
          La voce umana, è un veicolo d’espressione tra i più sofisticati in
          assoluto.
          <br /> La qualità della voce di ogni persona ne influenza la vita, più
          di quanto non si creda comunemente.
          <br /> Ciao, io sono _______ e se vorrai potrai usare la mia voce per
          comunicare con gli altri.
          <br /> Potrai usare la mia voce in tutte le situazioni, sia per
          esprimere gioia che preoccupazione, per far sapere quando sei felice,
          o quando qualcosa ti fà arrabbiare.
          <br />
          <br /> Ad esempio:
          <br /> 😊 Oggi è proprio una bellissima giornata, guarda che bel sole!
          😊
          <br /> 😔 Ieri, appena ho sentito di quella brutta notizia, ho subito
          iniziato a piangere. 😔 <br /> 😡 È mai possibile che debba sempre
          ricordarti io, di fare ordine in cucina? 😡
        </p>
      );
    case 'es-ES':
      return (
        <p>
          La voz es uno de los instrumentos más versátiles del ser humano.
          <br />
          La voz humana es uno de los vehículos de expresión más sofisticados.
          <br /> La calidad de la voz de cada persona influye en su vida más de
          lo que comúnmente se cree.
          <br /> Hola, soy _______ y si quieres puedes usar mi voz para
          comunicarte con los demás.
          <br /> Podrás usar mi voz en todas las situaciones, ya sea para
          expresar alegría o preocupación, para hacer saber cuándo estás feliz o
          cuándo algo te enfada.
          <br />
          <br /> Por ejemplo:
          <br /> 😊 Hoy hace un día precioso, ¡mira qué sol hace!😊 <br /> 😔
          Ayer, en cuanto me enteré de esa mala noticia, me puse a llorar. 😔
          <br /> 😡 ¿Será posible que siempre tenga que recordarte que pongas
          orden en la cocina? 😡
        </p>
      );

    case 'de-DE':
      return (
        <p>
          Die Stimme ist eines der vielseitigsten Instrumente des Menschen.
          <br />
          Die menschliche Stimme ist eines der ausgeklügeltsten Ausdrucksmittel
          überhaupt.
          <br /> Die Qualität der Stimme eines jeden Menschen beeinflusst sein
          Leben mehr, als allgemein angenommen wird.
          <br /> Hallo, ich bin _______ und wenn Sie möchten, können Sie meine
          Stimme verwenden, um mit anderen zu kommunizieren.
          <br /> Sie können meine Stimme in allen Situationen benutzen, sei es,
          um Freude oder Besorgnis auszudrücken, um andere wissen zu lassen,
          wenn Sie sich freuen, oder wenn Sie etwas wütend macht.
          <br />
          <br /> Zum Beispiel:
          <br /> 😊 Heute ist ein wunderschöner Tag, schau' wie schön die Sonne
          scheint!
          <br /> 😔 Als ich gestern von dieser schlechten Nachricht hörte, fing
          ich sofort an zu weinen. 😔 <br /> 😡 Kann es sein, dass ich dich
          immer daran erinnern muss, die Küche aufzuräumen? 😡
        </p>
      );
    case 'fr-FR':
      return (
        <p>
          La voix est l’un des instruments les plus polyvalents de l’être
          humain.
          <br /> La voix humaine est l’un des moyens d’expression les plus
          sophistiqués.
          <br /> La qualité de la voix de chaque personne influence sa vie et
          ce, bien plus qu’on ne le croit.
          <br /> Bonjour, je suis _______ et si vous le souhaitez, vous pouvez
          utiliser ma voix pour communiquer avec les autres.
          <br /> Vous pouvez utiliser ma voix dans toutes les situations, que ce
          soit pour exprimer de la joie ou de l’inquiétude, pour montrer que
          vous êtes heureux ou que quelque chose vous met en colère.
          <br />
          <br /> Par exemple :<br /> 😊 Aujourd’hui est une si belle journée,
          regardez comme il fait beau ! 😊
          <br /> 😔 Hier, dès que j’ai entendu parler de cette mauvaise
          nouvelle, j’ai immédiatement commencé à pleurer. 😔 <br />
          😡 Ce n’est pas possible, pourquoi dois-je toujours te rappeler de
          ranger la cuisine? 😡
        </p>
      );
    default:
      return <p></p>;
  }
}
