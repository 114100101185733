import React from 'react';

const ModalFaqContext = React.createContext<any>(null);

interface IProps {
  children: React.ReactElement | React.ReactElement[];
}
const ModalFaqProvider = ({ children }: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [faqNumberOpened, setFaqNumberOpened] = React.useState<number>(0);
  const [isLinkClicked, setIsLinkClicked] = React.useState(false);
  const [showFaq, setShowFaq] = React.useState(false);
  return (
    <ModalFaqContext.Provider
      value={
        {
          faqNumberOpened,
          setFaqNumberOpened,
          isLinkClicked,
          setIsLinkClicked,
          showFaq,
          setShowFaq,
        } as any
      }
    >
      {children}
    </ModalFaqContext.Provider>
  );
};

export { ModalFaqProvider, ModalFaqContext };
