/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import FaqModalContent from '@components/FaqModalContent';

type Props = {
  showFaq: boolean;
  closeModal: () => void;
};

export default function FaqModal({ showFaq, closeModal }: Props) {
  const { t } = useTranslation('faq');

  Modal.setAppElement('div');

  return (
    <Modal
      portalClassName={showFaq ? styles.wider : ''}
      isOpen={showFaq}
      onRequestClose={closeModal}
      overlayClassName={styles.overlay}
      className={styles.modal}
    >
      <FaqModalContent closeModal={closeModal} titleFaq={t('modalTitle')} />
    </Modal>
  );
}
