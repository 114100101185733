import { useState, useEffect } from 'react';

export default function useBrowserDetection() {
  const [browser, setBrowser] = useState('');

  useEffect(() => {
    const userAgent = navigator?.userAgent;

    setBrowser(userAgent);
  }, []);

  return browser;
}
