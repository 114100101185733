/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import styles from './styles.module.scss';
import platform from 'platform';
import ScriptInternalTool from '@components/ScriptInternalTool';

type IProps = {
  language: string;
};
const OnBoardingRightSideInternalTool = ({ language }: IProps) => {
  const { t } = useTranslation('onBoarding');
  const [currentBrowser, setCurrentBrowser] = React.useState('');
  function printScript() {
    window.print();
    if (currentBrowser === 'Safari') {
      document.execCommand('print', false);
    }
  }

  React.useEffect(() => {
    if (platform.name) {
      setCurrentBrowser(platform?.name);
    }
  }, []);

  function renderTitle() {
    switch (language) {
      case 'en-EN':
        return 'Voice sample script';
      case 'it-IT':
        // eslint-disable-next-line quotes
        return 'Script di esempio vocale';
      case 'es-ES':
        return 'Guión de muestra de voz';
      case 'de-DE':
        return 'Sprachbeispielskript';
      case 'fr-FR':
        // eslint-disable-next-line quotes
        return 'Exemple de script vocal';
      default:
        return '';
    }
  }

  function renderTitleMobile() {
    switch (language) {
      case 'en-EN':
        return 'Read this script from the beginning to the end';
      case 'it-IT':
        // eslint-disable-next-line quotes
        return "Leggi questo script dall'inizio alla fine";
      case 'es-ES':
        return 'Lea este guión completo desde el principio hasta el final';
      case 'de-DE':
        return 'Lesen Sie dieses gesamte Skript von Anfang bis Ende';
      case 'fr-FR':
        // eslint-disable-next-line quotes
        return "Lisez l'intégralité de ce script du début à la fin";
      default:
        return '';
    }
  }
  return (
    <>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.title}>
            <h4 className={styles.desktop}>{renderTitle()}</h4>
            <h4 className={styles.mobile}>{renderTitleMobile()}</h4>
            <FeatherIcon
              onClick={printScript}
              strokeWidth="2px"
              icon="printer"
              size="24"
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div className={styles.script}>
            <div className={styles.innerContent}>
              <ScriptInternalTool lang={language} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnBoardingRightSideInternalTool;
