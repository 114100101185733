import styles from './styles.module.scss';
import { WithChildren } from '@utils/types/helpers';

type Props = WithChildren<{
  message?: string | React.ReactNode;
  leadingIcon?: React.ReactNode;
  title?: string;
}>;

export default function SuccessMessageOnBoarding({
  message,
  leadingIcon,
  title,
  children,
}: Props) {
  return (
    <div className={styles.container}>
      {leadingIcon}
      {title && <h3>{title}</h3>}
      {message && <p>{message}</p>}
      {children && children}
    </div>
  );
}
