import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '@routes/routes';
import Header from '@components/Header';
import LayoutWrapper from '@components/LayoutWrapper';
import SplitLayout from '@components/SplitLayout';
import OnBoardingRightSide from '@components/OnBoardingRightSide';
import Button, {
  BUTTON_MODE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '@components/Button';
import FeatherIcon from 'feather-icons-react';
import { getCssVariable } from '@utils/index';
import SuccessMessageOnBoarding from '@components/SuccessMessageOnBoarding';
import { useFirestore, useUser } from 'reactfire';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import Loader from '@components/Loader';
import styles from './styles.module.scss';
import { Helmet } from 'react-helmet';
import imageAutodonate from '@assets/img/autodonate-pic.png';
import image from '@assets/img/onBoarding.jpg';

export default function ThankYou() {
  const { t } = useTranslation('thankYou');
  const navigate = useNavigate();
  const db = useFirestore();
  const { data: user } = useUser();
  const [isLoading, setIsLoading] = React.useState(true);

  function setDoneAndGoToDashboard() {
    updateUserData('done');
    navigate(ROUTES.BASE);
  }

  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isAutodonate = location?.state?.autodonate;

  const updateUserData = React.useCallback(
    async (step: string) => {
      if (user) {
        // await updateDoc(doc(db, 'users', user.uid), {
        //   onBoardingStep: step,
        // });

        await setDoc(
          doc(db, `users/${user.uid}/private`, 'donor'),
          {
            onBoardingStep: step,
          },
          { merge: true }
        );
      }
    },
    [db, user]
  );

  const getUserData = React.useCallback(async () => {
    if (user) {
      const userRef = doc(db, `users/${user.uid}/private`, 'donor');
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        const currentStep = userData?.onBoardingStep;

        if (currentStep === 'done') {
          navigate(`${ROUTES.BASE}`);
        } else {
          setIsLoading(false);
        }
      } else {
        console.log('No such document!');
      }
    }
  }, [user, db, navigate]);

  React.useEffect(() => {
    getUserData();
  }, [getUserData]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
        <meta name="description" content={t('descriptionPage')} />
      </Helmet>
      <Header buttonText={t('header')} />

      <LayoutWrapper>
        <SplitLayout
          left={
            <div className={styles.container}>
              <SuccessMessageOnBoarding
                title={
                  isAutodonate
                    ? t('titleAutodonate', {
                        name: user?.displayName?.split(' ')[0],
                      })
                    : t('title', {
                        name: user?.displayName?.split(' ')[0],
                      })
                }
                message={
                  isAutodonate ? t('descriptionAutodonate') : t('description')
                }
                leadingIcon={
                  <Button
                    style={{
                      backgroundColor: getCssVariable('green-light'),
                      cursor: 'default',
                    }}
                    type={BUTTON_TYPE.RECORDING}
                    size={BUTTON_SIZE.ONLY_ICON}
                    leadingIcon={
                      <FeatherIcon
                        strokeWidth="1px"
                        icon="check"
                        color={'var(--green)'}
                        size="40"
                      />
                    }
                  ></Button>
                }
              >
                <Button
                  onClick={setDoneAndGoToDashboard}
                  type={BUTTON_TYPE.PRIMARY}
                  mode={BUTTON_MODE.OUTLINE}
                >
                  {isAutodonate
                    ? t('labels.goToProfile')
                    : t('labels.backToDashboard')}
                </Button>
              </SuccessMessageOnBoarding>
            </div>
          }
          right={
            <OnBoardingRightSide
              urlImage={isAutodonate ? imageAutodonate : image}
            />
          }
        />
      </LayoutWrapper>
    </>
  );
}
