import React from 'react';
import LogoVfP from '@assets/logo/voice-for-purpose-black-logo.svg';
import LogoVfPSmall from '@assets/logo/translated-logo-t-black.svg';

const Logo = ({ full = false }) => {
  return (
    <a
      href="https://www.voiceforpurpose.com"
      target={'_blank'}
      rel="noreferrer"
    >
      <img src={full ? LogoVfP : LogoVfPSmall} alt="voice-logo" />
    </a>
  );
};

export default Logo;
