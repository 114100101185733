import styles from './styles.module.scss';
import Spinner from '@components/Spinner';

function Loader() {
  return (
    <div className={styles.container}>
      <Spinner />
    </div>
  );
}

export default Loader;
