/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Input from '@components/Input';
import Button, { BUTTON_MODE, BUTTON_TYPE } from '@components/Button';
import Spinner from '@components/Spinner';

import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';

export type User = {
  currentPassword: string;
  newPassword: string;
};

type Dirty = {
  currentPassword: boolean;
  newPassword: boolean;
};

type Props = {
  user: any;
  db: any;
  auth: any;
};

export default function ChangePassword({ user, db, auth }: Props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<{ message: string }>({
    message: '',
  });

  const {
    register,
    handleSubmit,
    watch,

    formState: { errors },
  } = useForm({ mode: 'onSubmit' });

  const currentPassword = watch('currentPassword');
  const newPassword = watch('newPassword');
  const [changePasswordSuccessfully, setChangePasswordSuccessfully] =
    React.useState(false);
  const [isDirty, setIsDirty] = React.useState<Dirty>({
    currentPassword: false,
    newPassword: false,
  });

  const { t } = useTranslation('dashboard');

  const { ref: currentPasswordRef, ...currentPasswordInputProps } = register(
    'currentPassword',
    {
      required: t('changePassword.form.errorsState.required.currentPassword'),
      minLength: {
        value: 6,
        message: t('changePassword.form.errorsState.invalid.passwordMinLength'),
      },
    }
  );

  const { ref: newPasswordRef, ...newPasswordInputProps } = register(
    'newPassword',
    {
      required: t('changePassword.form.errorsState.required.newPassword'),
      minLength: {
        value: 6,
        message: t('changePassword.form.errorsState.invalid.passwordMinLength'),
      },
    }
  );

  function handleOnSubmit({ currentPassword, newPassword }: User) {
    setIsDirty({
      currentPassword: currentPassword.length > 0,
      newPassword: newPassword.length > 0,
    } as Dirty);
    setIsLoading(true);
    onUpdatePassword({
      currentPassword,
      newPassword,
    } as User);
  }

  const onUpdatePassword = React.useCallback(
    async ({ currentPassword, newPassword }: User) => {
      if (user) {
        try {
          setIsLoading(true);
          const credential = EmailAuthProvider.credential(
            auth.currentUser.email,
            currentPassword as string
          );

          await reauthenticateWithCredential(auth.currentUser, credential);
          await updatePassword(auth.currentUser, newPassword);
          setChangePasswordSuccessfully(true);
          setTimeout(() => {
            setChangePasswordSuccessfully(false);
          }, 3000);
          setIsLoading(false);
        } catch (err) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (err?.code === 'auth/wrong-password') {
            setError({ message: 'Wrong password' });
            setTimeout(() => {
              setError({ message: '' });
            }, 2000);
          }
        } finally {
          setIsLoading(false);
        }
      }
    },
    [user, auth.currentUser]
  );

  return (
    <>
      <div className={styles.container}>
        <form onSubmit={handleSubmit(handleOnSubmit as any)}>
          <fieldset className={styles.isHalf}>
            <Input
              hasValue={isDirty?.currentPassword ?? false}
              label={t('changePassword.form.labels.currentPassword')}
              type="password"
              placeholder={t('changePassword.form.placeholder.password')}
              inputRef={currentPasswordRef}
              {...currentPasswordInputProps}
              error={errors?.currentPassword?.message}
            />

            <Input
              hasValue={isDirty?.newPassword ?? false}
              label={t('changePassword.form.labels.newPassword')}
              type="password"
              placeholder={t('changePassword.form.placeholder.password')}
              inputRef={newPasswordRef}
              {...newPasswordInputProps}
              error={errors?.newPassword?.message}
            />
          </fieldset>
          <div className={styles.buttonUpdate}>
            <Button
              type={BUTTON_TYPE.PRIMARY}
              mode={BUTTON_MODE.OUTLINE}
              disabled={!currentPassword || !newPassword}
            >
              {isLoading ? (
                <Spinner isMid />
              ) : (
                t('changePassword.form.labels.update')
              )}
            </Button>

            {error?.message && <p className={styles.error}>{error?.message}</p>}
            {changePasswordSuccessfully && (
              <p className={styles.success}>
                {t('changePassword.form.success.passwordChanged')}
              </p>
            )}
          </div>
        </form>
      </div>
    </>
  );
}
