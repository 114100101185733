import { InputHTMLAttributes } from 'react';
import styles from './styles.module.scss';
import { noop, omit } from 'lodash';
import { RefCallBack } from 'react-hook-form';

export enum CHECKBOX_MODE {
  INTERMEDIATE = 'intermediate',
  DEFAULT = 'default',
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | React.ReactNode;
  placeholder?: string;
  inputRef?: RefCallBack; // this should be mandatory if onChange prop is not provided.
  onChange?: typeof noop; // this should be mandatory if inputRef prop is not provided.
  error?: string;
  isDisabled?: boolean;
  hasValue?: boolean;
  mode?: CHECKBOX_MODE;
}

export default function InputCheckbox(props: InputProps) {
  const {
    error = '',
    label = '',
    hasValue = false,
    mode = CHECKBOX_MODE.DEFAULT,
    isDisabled = false,
    onChange = noop,
  } = props;

  return (
    <div className={styles.container}>
      <div
        className={`${styles.wrapper} ${error ? styles.error : ''}  ${
          hasValue ? styles.isDirty : ''
        }  
          `}
      >
        <label className="small">
          <div className={`${styles.inputWrapper}`}>
            <input
              disabled={isDisabled}
              tabIndex={0}
              ref={props.inputRef}
              onChange={onChange}
              {...omit(props, ['inputRef', 'hasValue', 'error', 'isDisabled'])}
              className={styles[mode]}
            />

            <div className={styles.checkboxText}>{label ? label : ''}</div>
          </div>
        </label>
      </div>
      {error ? <p>{error}</p> : null}
    </div>
  );
}
