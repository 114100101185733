import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// COUNTRIES
import countriesEN from '@locales/en/countries.json';
import countries from '@locales/it/countries.json';
import countriesFR from '@locales/fr/countries.json';
import countriesES from '@locales/es/countries.json';
import countriesDE from '@locales/de/countries.json';

// SIGN IN
import signInEN from '@locales/en/signIn.json';
import signIn from '@locales/it/signIn.json';
import signInFR from '@locales/fr/signIn.json';
import signInES from '@locales/es/signIn.json';
import signInDE from '@locales/de/signIn.json';

// SIGN UP
import signUpEN from '@locales/en/signUp.json';
import signUp from '@locales/it/signUp.json';
import signUpFR from '@locales/fr/signUp.json';
import signUpES from '@locales/es/signUp.json';
import signUpDE from '@locales/de/signUp.json';

// NOT FOUND
import notFoundEN from '@locales/en/notFound.json';
import notFound from '@locales/it/notFound.json';
import notFoundFR from '@locales/fr/notFound.json';
import notFoundES from '@locales/es/notFound.json';
import notFoundDE from '@locales/de/notFound.json';

// PRIVACY POLICY
import privacyPolicyEN from '@locales/en/privacyPolicy.json';
import privacyPolicy from '@locales/it/privacyPolicy.json';
import privacyPolicyFR from '@locales/fr/privacyPolicy.json';
import privacyPolicyES from '@locales/es/privacyPolicy.json';
import privacyPolicyDE from '@locales/de/privacyPolicy.json';

// PASSWORD RESET
import passwordResetEN from '@locales/en/passwordReset.json';
import passwordReset from '@locales/it/passwordReset.json';
import passwordResetFR from '@locales/fr/passwordReset.json';
import passwordResetES from '@locales/es/passwordReset.json';
import passwordResetDE from '@locales/de/passwordReset.json';

// SIGN IN CONTENT
import signInContentEN from '@locales/en/signInContent.json';
import signInContent from '@locales/it/signInContent.json';
import signInContentFR from '@locales/fr/signInContent.json';
import signInContentES from '@locales/es/signInContent.json';
import signInContentDE from '@locales/de/signInContent.json';

// ON BOARDING
import onBoardingEN from '@locales/en/onBoarding.json';
import onBoarding from '@locales/it/onBoarding.json';
import onBoardingFR from '@locales/fr/onBoarding.json';
import onBoardingES from '@locales/es/onBoarding.json';
import onBoardingDE from '@locales/de/onBoarding.json';

// LIBRARY
import libraryEN from '@locales/en/library.json';
import library from '@locales/it/library.json';
import libraryFR from '@locales/fr/library.json';
import libraryES from '@locales/es/library.json';
import libraryDE from '@locales/de/library.json';

// DASHBOARD
import dashboardEN from '@locales/en/dashboard.json';
import dashboard from '@locales/it/dashboard.json';
import dashboardFR from '@locales/fr/dashboard.json';
import dashboardES from '@locales/es/dashboard.json';
import dashboardDE from '@locales/de/dashboard.json';

// NEW PASSWORD
import newPasswordEN from '@locales/en/newPassword.json';
import newPassword from '@locales/it/newPassword.json';
import newPasswordFR from '@locales/fr/newPassword.json';
import newPasswordES from '@locales/es/newPassword.json';
import newPasswordDE from '@locales/de/newPassword.json';

// THANK YOU
import thankYouEN from '@locales/en/thankYou.json';
import thankYou from '@locales/it/thankYou.json';
import thankYouFR from '@locales/fr/thankYou.json';
import thankYouES from '@locales/es/thankYou.json';
import thankYouDE from '@locales/de/thankYou.json';

// MODEL RECORDING
import modelRecordingEN from '@locales/en/modelRecording.json';
import modelRecording from '@locales/it/modelRecording.json';
import modelRecordingFR from '@locales/fr/modelRecording.json';
import modelRecordingES from '@locales/es/modelRecording.json';
import modelRecordingDE from '@locales/de/modelRecording.json';

// FAQ
import faqEN from '@locales/en/faq.json';
import faq from '@locales/it/faq.json';
import faqFR from '@locales/fr/faq.json';
import faqES from '@locales/es/faq.json';
import faqDE from '@locales/de/faq.json';

// RECOVER EMAIL
import recoverEmailEN from '@locales/en/recoverEmail.json';
import recoverEmail from '@locales/it/recoverEmail.json';
import recoverEmailFR from '@locales/fr/recoverEmail.json';
import recoverEmailES from '@locales/es/recoverEmail.json';
import recoverEmailDE from '@locales/de/recoverEmail.json';

declare module 'react-i18next' {
  interface CustomTypeOptions {
    defaultNS: 'it';
    resources: {
      privacyPolicy: typeof privacyPolicy;
      privacyPolicyEN: typeof privacyPolicyEN;
      privacyPolicyFR: typeof privacyPolicyFR;
      privacyPolicyES: typeof privacyPolicyES;
      privacyPolicyDE: typeof privacyPolicyDE;

      countries: typeof countries;
      countriesEN: typeof countriesEN;
      countriesFR: typeof countriesFR;
      countriesES: typeof countriesES;
      countriesDE: typeof countriesDE;

      notFound: typeof notFound;
      notFoundEN: typeof notFoundEN;
      notFoundFR: typeof notFoundFR;
      notFoundES: typeof notFoundES;
      notFoundDE: typeof notFoundDE;

      signIn: typeof signIn;
      signInEN: typeof signInEN;
      signInFR: typeof signInFR;
      signInES: typeof signInES;
      signInDE: typeof signInDE;

      signUp: typeof signUp;
      signUpEN: typeof signUpEN;
      signUpFR: typeof signUpFR;
      signUpES: typeof signUpES;
      signUpDE: typeof signUpDE;

      passwordReset: typeof passwordReset;
      passwordResetEN: typeof passwordResetEN;
      passwordResetFR: typeof passwordResetFR;
      passwordResetES: typeof passwordResetES;
      passwordResetDE: typeof passwordResetDE;

      signInContent: typeof signInContent;
      signInContentEN: typeof signInContentEN;
      signInContentFR: typeof signInContentFR;
      signInContentES: typeof signInContentES;
      signInContentDE: typeof signInContentDE;

      dashboard: typeof dashboard;
      dashboardEN: typeof dashboardEN;
      dashboardFR: typeof dashboardFR;
      dashboardES: typeof dashboardES;
      dashboardDE: typeof dashboardDE;

      library: typeof library;
      libraryEN: typeof libraryEN;
      libraryFR: typeof libraryFR;
      libraryES: typeof libraryES;
      libraryDE: typeof libraryDE;

      onBoarding: typeof onBoarding;
      onBoardingEN: typeof onBoardingEN;
      onBoardingFR: typeof onBoardingFR;
      onBoardingES: typeof onBoardingES;
      onBoardingDE: typeof onBoardingDE;

      newPassword: typeof newPassword;
      newPasswordEN: typeof newPasswordEN;
      newPasswordFR: typeof newPasswordFR;
      newPasswordES: typeof newPasswordES;
      newPasswordDE: typeof newPasswordDE;

      thankYou: typeof thankYou;
      thankYouEN: typeof thankYouEN;
      thankYouFR: typeof thankYouFR;
      thankYouES: typeof thankYouES;
      thankYouDE: typeof thankYouDE;

      modelRecording: typeof modelRecording;
      modelRecordingEN: typeof modelRecordingEN;
      modelRecordingFR: typeof modelRecordingFR;
      modelRecordingES: typeof modelRecordingES;
      modelRecordingDE: typeof modelRecordingDE;

      faq: typeof faq;
      faqEN: typeof faqEN;
      faqFR: typeof faqFR;
      faqES: typeof faqES;
      faqDE: typeof faqDE;

      recoverEmail: typeof recoverEmail;
      recoverEmailEN: typeof recoverEmailEN;
      recoverEmailFR: typeof recoverEmailFR;
      recoverEmailES: typeof recoverEmailES;
      recoverEmailDE: typeof recoverEmailDE;
    };
  }
}

export const resources = {
  en: {
    signIn: signInEN,
    signUp: signUpEN,
    privacyPolicy: privacyPolicyEN,
    passwordReset: passwordResetEN,
    signInContent: signInContentEN,
    onBoarding: onBoardingEN,
    dashboard: dashboardEN,
    newPassword: newPasswordEN,
    thankYou: thankYouEN,
    faq: faqEN,
    recoverEmail: recoverEmailEN,
    countries: countriesEN,
    notFound: notFoundEN,
    library: libraryEN,
    modelRecording: modelRecordingEN,
  },
  it: {
    signIn: signIn,
    signUp: signUp,
    privacyPolicy: privacyPolicy,
    passwordReset: passwordReset,
    signInContent: signInContent,
    onBoarding: onBoarding,
    dashboard: dashboard,
    newPassword: newPassword,
    thankYou: thankYou,
    faq: faq,
    recoverEmail: recoverEmail,
    countries: countries,
    notFound: notFound,
    library: library,
    modelRecording: modelRecording,
  },
  fr: {
    signIn: signInFR,
    signUp: signUpFR,
    privacyPolicy: privacyPolicyFR,
    passwordReset: passwordResetFR,
    signInContent: signInContentFR,
    onBoarding: onBoardingFR,
    dashboard: dashboardFR,
    newPassword: newPasswordFR,
    thankYou: thankYouFR,
    faq: faqFR,
    recoverEmail: recoverEmailFR,
    countries: countriesFR,
    notFound: notFoundFR,
    library: libraryFR,
    modelRecording: modelRecordingFR,
  },
  es: {
    signIn: signInES,
    signUp: signUpES,
    privacyPolicy: privacyPolicyES,
    passwordReset: passwordResetES,
    signInContent: signInContentES,
    onBoarding: onBoardingES,
    dashboard: dashboardES,
    newPassword: newPasswordES,
    thankYou: thankYouES,
    faq: faqES,
    recoverEmail: recoverEmailES,
    countries: countriesES,
    notFound: notFoundES,
    library: libraryES,
    modelRecording: modelRecordingES,
  },
  de: {
    signIn: signInDE,
    signUp: signUpDE,
    privacyPolicy: privacyPolicyDE,
    passwordReset: passwordResetDE,
    signInContent: signInContentDE,
    onBoarding: onBoardingDE,
    dashboard: dashboardDE,
    newPassword: newPasswordDE,
    thankYou: thankYouDE,
    faq: faqDE,
    recoverEmail: recoverEmailDE,
    countries: countriesDE,
    notFound: notFoundDE,
    library: libraryDE,
    modelRecording: modelRecordingDE,
  },
} as const;
const supportedLang = ['it-IT', 'fr-FR', 'en-EN', 'de-DE', 'es-ES'];

export const defaultNS = supportedLang.includes(navigator.language)
  ? navigator.language
  : 'it';
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: {
      order: ['cookie'],
      lookupCookie: 'lang',
    },
    fallbackLng: defaultNS,
    ns: [
      'signIn',
      'signUp',
      'privacyPolicy',
      'passwordReset',
      'signInContent',
      'onBoarding',
      'dashboard',
      'newPassword',
      'thankYou',
      'faq',
      'recoverEmail',
      'countries',
      'notFound',
      'library',
      'modelRecording',
    ],
  });

export default i18n;
