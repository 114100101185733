/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import { useWindowSize } from '@hooks/useWindowResize';
import Button, {
  BUTTON_MODE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '@components/Button';

import { getCssVariable } from '@utils/index';

type IProps = {
  closeModal: any;
  faqOpened: number;
  closeFirstModal: any;
};

export default function FaqItem({
  closeFirstModal,
  closeModal,
  faqOpened,
}: IProps) {
  const { t } = useTranslation('faq');
  const size = useWindowSize();

  function generateTitle() {
    switch (faqOpened) {
      case 1:
        return t('faq1.faqTitle');
      case 2:
        return t('faq2.faqTitle');
      case 3:
        return t('faq3.faqTitle');
      case 4:
        return t('faq4.faqTitle');
      default:
        return '';
    }
  }

  function generateContent() {
    switch (faqOpened) {
      case 1:
        return t('faq1.faqContent');
      case 2:
        return t('faq2.faqContent');
      case 3:
        return t('faq3.faqContent');
      case 4:
        return t('faq4.faqContent');
      default:
        return '';
    }
  }

  return (
    <div className={styles.faqContainer}>
      <div className={styles.inner}>
        <div className={styles.header}>
          <div className={styles.backWrapper}>
            <Button
              type={BUTTON_TYPE.DEFAULT}
              mode={BUTTON_MODE.GHOST}
              size={BUTTON_SIZE.ONLY_ICON}
              onClick={closeModal}
              leadingIcon={
                <FeatherIcon
                  strokeWidth="2px"
                  aria-hidden="true"
                  color={getCssVariable('neutral600')}
                  icon="arrow-left"
                  size="24"
                />
              }
            ></Button>

            <h4 className={size.width <= 500 ? styles.truncate : ''}>
              {generateTitle()}
            </h4>
          </div>
          <Button
            type={BUTTON_TYPE.DEFAULT}
            mode={BUTTON_MODE.GHOST}
            size={BUTTON_SIZE.ONLY_ICON}
            onClick={closeFirstModal}
            leadingIcon={
              <FeatherIcon
                strokeWidth="2px"
                aria-hidden="true"
                color={getCssVariable('neutral600')}
                icon="x"
                size="24"
              />
            }
          ></Button>
        </div>
        <div className={styles.container}>
          <p>{generateContent()}</p>
        </div>
      </div>
    </div>
  );
}
