import axios from 'axios';

export const http = axios.create({
  baseURL: process.env.REACT_APP_VALIDATE_AUDIO_API_URL,
  timeout: process.env.NODE_ENV === 'production' ? 60000 : 40000,
});


export enum VALIDATION_AUDIO_RESPONSE {
  ACCEPT = 'accept',
  REJECT = 'reject',
}

type validateAudioFileResponse = {
  language: string;
  text: string;
  transcription: string;
  validation: VALIDATION_AUDIO_RESPONSE;
  validation_time: string;
};

export async function validateAudioFile(
  formData: any
): Promise<validateAudioFileResponse> {
  try {

    const { data }: { data: validateAudioFileResponse } = await http.post(
      '/api/recognize',
      formData,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_VALIDATE_AUDIO_API_TOKEN}`,
        },
      }
    );
    return data;
  } catch (err) {
    throw err;
  }
}
