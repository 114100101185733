/* eslint-disable @typescript-eslint/no-unused-vars */
import Header from '@components/Header';
import LayoutWrapper from '@components/LayoutWrapper';
import Loader from '@components/Loader';
import OnBoardingRightSide from '@components/OnBoardingRightSide';
import SplitLayout from '@components/SplitLayout';
import { ROUTES } from '@routes/routes';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUser, useFirestore } from 'reactfire';
import recordModelRight from '@assets/img/recordModelRight.png';
import { Helmet } from 'react-helmet';
import VoiceModel from '@components/VoiceModel';

export default function VoiceModelPage() {
  const { t } = useTranslation('onBoarding');

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const [language, setLanguage] = React.useState('');
  const db = useFirestore();
  const { data: user } = useUser();

  const getUserData = React.useCallback(async () => {
    if (user) {
      const userRef = doc(db, 'users', user?.uid);
      const docSnap = await getDoc(userRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        const recordingModelStep = userData?.recordModelStep;

        setLanguage(userData?.language);

        if (recordingModelStep) {
          navigate(ROUTES.RECORD_MODEL);
        } else {
          setIsLoading(false);
        }
      } else {
        console.log('No such document!');
      }
    }
  }, [user, db, navigate]);

  React.useEffect(() => {
    getUserData();
  }, [getUserData]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>{t('externalTool.pageTitleChooseTool')}</title>
        <meta
          name="description"
          content={t('externalTool.descriptionPageChooseTool')}
        />
      </Helmet>
      <Header destination={'help'} buttonText={t('externalTool.header')} />

      <LayoutWrapper>
        <SplitLayout
          left={<VoiceModel lang={language} />}
          right={<OnBoardingRightSide urlImage={recordModelRight} />}
        />
      </LayoutWrapper>
    </>
  );
}
