import Button, { BUTTON_MODE, BUTTON_TYPE } from '@components/Button';
import ChangePassword from '@components/ChangePassword';
import DeleteAccount from '@components/DeleteAccount';
import Header from '@components/Header';
import Loader from '@components/Loader';
import PersonalDetails from '@components/PersonalDetails';
import WhatsNext from '@components/WhatsNext';
import { ROUTES } from '@routes/routes';
import { signOut } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUser, useFirestore, useAuth } from 'reactfire';
import styles from './styles.module.scss';
import { Helmet } from 'react-helmet';
import VoicePreview from '@components/VoicePreview';
import Cookies from 'js-cookie';
import AutodonatePersonalDetails from '@components/AutodonatePersonalDetails';

export default function Dashboard({ initialLang }: { initialLang: string }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { i18n } = useTranslation();

  const { t } = useTranslation('dashboard');
  const navigate = useNavigate();
  const db = useFirestore();
  const { data: user } = useUser();
  const auth = useAuth();
  const [isLoading, setIsLoading] = React.useState(true);
  const [userDescription, setUserDescription] = React.useState('');
  const [currentUserData, setCurrentUserData] = React.useState<any>();
  const [userDataPrivate, setUserDataPrivate] = React.useState<any>();
  const [avatarPhoto, setAvatarPhoto] = React.useState('');
  const [userLang, setUserLang] = React.useState('');
  const [userType, setUserType] = React.useState('');
  const [isAutodonate, setIsAutoDonate] = React.useState(false);
  const [isRecordModelEnabled, setIsRecordModelEnabled] =
    React.useState<boolean>(false);
  const [recordVoiceModelStep, setRecordVoiceModelStep] =
    React.useState<number>(0);
  const [isModelVoiceRecordingComplete, setIsModelVoiceRecordingComplete] =
    React.useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  React.useEffect(() => {
    const langFromCookie = Cookies.get('lang');
    i18n.changeLanguage(langFromCookie || window.navigator.language);
  }, [i18n]);

  const getUserData = React.useCallback(async () => {
    if (user) {
      const userRefPublic = doc(db, 'users', user?.uid);
      const docSnapPublic = await getDoc(userRefPublic);
      const userDataPublic = docSnapPublic.data();
      setUserLang(userDataPublic?.language);
      setUserType(userDataPublic?.userType);
      setIsRecordModelEnabled(userDataPublic?.enableRecording ?? false);
      setCurrentUserData(userDataPublic);
      if (userDataPublic?.userType === 'library') {
        navigate(ROUTES.FINISH_REGISTRATION);
      }

      const userRefPrivate = doc(db, `users/${user.uid}/private`, 'donor');
      const docSnapPrivate = await getDoc(userRefPrivate);

      if (docSnapPublic.exists() && docSnapPrivate.exists()) {
        const userDataPublic = docSnapPublic.data();
        const userDataPrivate = docSnapPrivate.data();

        const currentStep = userDataPrivate?.onBoardingStep;
        const isChanging = userDataPrivate?.isChangingRecording;
        setUserDataPrivate(userDataPrivate);
        // const recordingTool = userData?.recordingTool;
        setUserDescription(userDataPublic?.describeYourself ?? '');
        setAvatarPhoto(userDataPublic?.avatar_photo);
        setRecordVoiceModelStep(userDataPublic?.recordModelStep);
        setIsModelVoiceRecordingComplete(
          userDataPublic?.isScriptModelVoiceComplete
        );
        setIsAutoDonate(userDataPublic?.isAutodonate);

        if (currentStep === 'done' || currentStep === 'submit' || isChanging) {
          setIsLoading(false);

          await setDoc(
            doc(db, `users/${user.uid}/private`, 'donor'),
            {
              onBoardingStep: 'done',
            },
            { merge: true }
          );
        } else {
          navigate(`${ROUTES.ONBOARDING}`);
        }
      } else {
        console.log('No such document!');
      }
    }
  }, [user, db, navigate]);

  React.useEffect(() => {
    getUserData();
  }, [getUserData]);

  const handleSignOut = async (auth: any) => {
    await signOut(auth);
    navigate(ROUTES.SIGN_IN);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
        <meta name="description" content={t('descriptionPage')} />
      </Helmet>
      <Header destination={'help'} buttonText={t('header')} />

      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.user}>
            <h3>
              {t('welcome', {
                value: user?.displayName?.split(' ')[0],
              })}
            </h3>
            <Button
              onClick={() => handleSignOut(auth)}
              type={BUTTON_TYPE.PRIMARY}
              mode={BUTTON_MODE.GHOST}
            >
              {t('logOut')}
            </Button>
          </div>
          <div className={styles.blockTitle}>
            <h4>{t('whatsNext.title')}</h4>
          </div>
          <WhatsNext
            user={user}
            isVoiceModelComplete={isModelVoiceRecordingComplete}
            recordModelStep={recordVoiceModelStep}
            isAutodonate={isAutodonate}
            isModelRecordingEnabled={isRecordModelEnabled}
          />

          <div className={styles.blockTitle}>
            <h4>{t('personalDetails.title')}</h4>
          </div>

          {!isAutodonate ? (
            <PersonalDetails
              userDescription={userDescription}
              db={db}
              user={user}
              auth={auth}
              avatarPhoto={avatarPhoto}
              userType={userType}
            />
          ) : (
            <AutodonatePersonalDetails
              userType={userType}
              userData={userDataPrivate}
              db={db}
              user={user}
              auth={auth}
            />
          )}

          {!isAutodonate && (
            <>
              <div className={styles.blockTitle}>
                <h4>{t('voicePreview.title')}</h4>
              </div>
              <VoicePreview db={db} user={currentUserData} />
            </>
          )}

          <div className={styles.blockTitle}>
            <h4>{t('changePassword.title')}</h4>
          </div>
          <ChangePassword db={db} user={user} auth={auth} />

          <div className={styles.blockTitle}>
            <h4>{t('deleteAccount.title')}</h4>
          </div>
          <DeleteAccount
            requestedDeletionAccount={userDataPrivate?.requestedDeletionAccount}
            db={db}
            user={user}
            auth={auth}
            userLang={userLang}
            userType={userType}
          />
        </div>
      </div>
    </>
  );
}
