import { WithChildren } from '@utils/types/helpers';
import React from 'react';
import styles from './styles.module.scss';
export default function LayoutWrapper({ children }: WithChildren) {
  return (
    <div className={styles.layout}>
      <div className={styles.wrapper}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
}
