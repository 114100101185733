import * as React from 'react';
import { useSigninCheck } from 'reactfire';
import Loader from '@components/Loader';
export const AuthWrapper = ({
  children,
  fallback,
}: React.PropsWithChildren<{ fallback: JSX.Element }>) => {
  const { status, data: signInCheckResult } = useSigninCheck();

   if (!children) {
    throw new Error('Children must be provided');
  }
  if (status === 'loading') {
    return <Loader />;
  } else if (signInCheckResult.signedIn === true) {
    return children as JSX.Element;
  }

  return fallback;
};
