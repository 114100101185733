import React from 'react';

const VoiceUploaderContext = React.createContext<any>(null);

interface IProps {
  children: React.ReactElement | React.ReactElement[];
}
const VoiceUploaderProvider = ({ children }: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [file, setFile] = React.useState(null);
  return (
    <VoiceUploaderContext.Provider value={{ file, setFile } as any}>
      {children}
    </VoiceUploaderContext.Provider>
  );
};

export { VoiceUploaderProvider, VoiceUploaderContext };
