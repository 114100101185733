import * as React from 'react';
import styles from './styles.module.scss';
import { omit } from 'lodash';
import { useWindowSize } from '@hooks/useWindowResize';
import { useTranslation } from 'react-i18next';
import FilterDropdown from '@components/FilterDropdown';
import { Swiper, SwiperSlide } from 'swiper/react';
import Button, {
  BUTTON_MODE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '@components/Button';
import magic from '@assets/img/magic.svg';
interface IProps {
  filters: any;
  initialState: any;
  onSetFilters: (params: any) => void;
  currentUserData: any;
}

enum FACET_TYPE {
  CHECKBOX = 'checkbox',
  RANGE = 'range',
  RADIO = 'radio',
}

export default function Filters({
  filters,
  onSetFilters,
  initialState,
  currentUserData,
}: IProps) {
  const { t } = useTranslation('library');
  const windowSize = useWindowSize();
  const mainFilters = filters;

  const parsedInitialState = React.useMemo(() => {
    return Object.keys(initialState).reduce(
      (acc, item) => ({
        ...acc,
        [item]: initialState[item].split(','),
      }),
      {}
    );
  }, [initialState]);

  const isMobile = windowSize.width < 600;
  const [currentKey, setCurrentKey] = React.useState<string>('');
  const [globalState, setGlobalState] = React.useState<any>(parsedInitialState);

  React.useEffect(() => {
    setGlobalState(parsedInitialState);
  }, [parsedInitialState]);

  function handleOnChangeFilters(
    filterId: string,
    filtervalue: any,
    filterType: FACET_TYPE
  ) {
    // if (filterType === FACET_TYPE.CHECKBOX || filterType === FACET_TYPE.RADIO) {
    const languageFallback =
      filtervalue.length === 0 && filterId === 'language'
        ? ['en-EN']
        : filtervalue.length === 0 && filterId === 'gender'
        ? ['male']
        : ['young', 'middle', 'senior'];

    return {
      ...globalState,
      [filterId]: filtervalue.length > 0 ? filtervalue : languageFallback,
    };
    // }

    // if (filterType === FACET_TYPE.RANGE) {
    //   const [min, max] = filtervalue;
    //   const re = /^[0-9]{0,2}$/;

    //   return {
    //     ...globalState,
    //     [filterId]: [
    //       `${min && min !== '0' && re.test(min) ? min : 0}`,
    //       `${max && max !== '0' && re.test(max) ? max : 99}`,
    //     ],
    //   };
    // } else {
    //   return { ...globalState };
    // }
  }

  function handleOnSetFilters(
    id: string,
    filterState: string[] | boolean[],
    filterType: FACET_TYPE
  ) {
    const newState = handleOnChangeFilters(id, filterState, filterType);
    return newState;
    // if (filterType === FACET_TYPE.CHECKBOX || filterType === FACET_TYPE.RADIO) {
    //   const newState = handleOnChangeFilters(id, filterState, filterType);
    //   return newState;
    // }

    // if (filterType === FACET_TYPE.RANGE) {
    //   return handleOnChangeFilters(id, filterState, filterType);
    // }
  }

  function handleOnSetFilterForSingleCheckboxFilter(
    id: string,
    filterState: string[],
    filterType: FACET_TYPE
  ): void {
    const newState = {
      ...handleOnSetFilters(id, filterState, filterType),
    };

    if (newState) {
      setGlobalState(newState);
      onSetFilters(newState);
    }
  }

  function handleOnRemoveFilters(ids: string[], state: any) {
    return omit(state, ids);
  }

  const handleOnRemoveFilterForSingleCheckboxFilter = (id: string) => () => {
    let newState = {};

    newState = {
      ...handleOnRemoveFilters([id], globalState),
    };

    if (newState) {
      setGlobalState(newState);
      onSetFilters(newState);
    }
  };

  function handleOnRemoveAllFilters() {
    setGlobalState({ sortBy: globalState.sortBy });
    onSetFilters({ sortBy: globalState.sortBy });
  }

  function translateLabelName(val: any) {
    switch (val) {
      case 'en-EN':
        return t('filters.labels.languages.english');
      case 'it-IT':
        return t('filters.labels.languages.italian');
      case 'es-ES':
        return t('filters.labels.languages.spanish');
      case 'de-DE':
        return t('filters.labels.languages.german');
      case 'fr-FR':
        return t('filters.labels.languages.french');
      case 'male':
        return t('filters.labels.male');
      case 'female':
        return t('filters.labels.female');
      case 'notBinary':
        return t('filters.labels.notBinary');
      case 'young':
        return t('filters.labels.age.young');
      case 'senior':
        return t('filters.labels.age.senior');
      case 'middle':
        return t('filters.labels.age.middle');

      default:
        return val;
    }
  }

  function renderMainFiltersWithSlide(filter: any, key: number) {
    // let normalizedCurrentValue;
    // if (filter.id !== 'age') {
    const normalizedCurrentValue = filter.value.map((v: any) => {
      if (
        Object.keys(globalState).length !== 0 &&
        globalState[filter.id] &&
        globalState[filter.id].includes(v.value)
      ) {
        let res = '';
        res += translateLabelName(v.value);

        return res;
      }
    });
    // }
    return (
      <SwiperSlide key={key} className={styles.slide}>
        <FilterDropdown
          isActive={filter.isActive}
          type={filter.type}
          currentKey={currentKey}
          setCurrentKey={setCurrentKey}
          id={filter.id}
          label={!filter.isActive ? filter.label : normalizedCurrentValue}
          value={filter.value}
          selectedValues={(globalState as any)[filter.id] || []}
          onSetFilter={handleOnSetFilterForSingleCheckboxFilter}
          onRemoveFilter={handleOnRemoveFilterForSingleCheckboxFilter(
            filter.id
          )}
          icon={filter.icon}
          globalState={globalState}
        />
      </SwiperSlide>
    );
  }

  function renderMainFilters(filter: any, key: number) {
    // let normalizedCurrentValue;
    // if (filter.id !== 'age') {
    const normalizedCurrentValue = filter.value.map((v: any) => {
      if (
        Object.keys(globalState).length !== 0 &&
        globalState[filter.id] &&
        globalState[filter.id].includes(v.value)
      ) {
        let res = '';
        res += translateLabelName(v.value);

        return res;
      }
    });
    // }

    return (
      <React.Fragment key={key}>
        <FilterDropdown
          isActive={filter.isActive}
          type={filter.type}
          currentKey={currentKey}
          setCurrentKey={setCurrentKey}
          id={filter.id}
          label={!filter.isActive ? filter.label : normalizedCurrentValue}
          value={filter.value}
          selectedValues={(globalState as any)[filter.id] || []}
          onSetFilter={handleOnSetFilterForSingleCheckboxFilter}
          onRemoveFilter={handleOnRemoveFilterForSingleCheckboxFilter(
            filter.id
          )}
          icon={filter.icon}
          globalState={globalState}
        />
      </React.Fragment>
    );
  }

  const hasFiltersActive = mainFilters.filter((f: any) => f.isActive).length;

  function setSuggestedFilters() {
    onSetFilters({
      age: [currentUserData?.voiceType],
      gender: [currentUserData?.gender],
      sortBy: ['-inserted'],
      language: [currentUserData?.language],
    });
  }

  return (
    <>
      <div className={styles.container}>
        {isMobile ? (
          <>
            <Swiper
              className={styles.swiper}
              observer={true}
              observeParents={true}
              observeSlideChildren={true}
              freeMode={true}
              spaceBetween={8}
              slidesPerView={'auto'}
              tag="ul"
              touchRatio={1}
              direction={'horizontal'}
            >
              {mainFilters.map(renderMainFiltersWithSlide)}{' '}
              <SwiperSlide className={styles.slide}>
                {hasFiltersActive ? (
                  <span
                    className={styles.removeFilterButton}
                    onClick={handleOnRemoveAllFilters}
                    aria-hidden
                  >
                    {t('filters.labels.removeFilters')}
                  </span>
                ) : (
                  <Button
                    type={BUTTON_TYPE.DEFAULT}
                    mode={BUTTON_MODE.GHOST}
                    size={BUTTON_SIZE.WITH_ICON}
                    onClick={setSuggestedFilters}
                    leadingIcon={
                      <img className={styles.magicIcon} src={magic} />
                    }
                  >
                    {t('filters.labels.showSuggested')}
                  </Button>
                )}
              </SwiperSlide>
            </Swiper>
          </>
        ) : (
          <>
            {mainFilters.map(renderMainFilters)}{' '}
            {hasFiltersActive ? (
              <span
                className={styles.removeFilterButton}
                onClick={handleOnRemoveAllFilters}
                aria-hidden
              >
                {t('filters.labels.removeFilters')}
              </span>
            ) : (
              <div className={styles.showSuggested}>
                <Button
                  type={BUTTON_TYPE.DEFAULT}
                  mode={BUTTON_MODE.GHOST}
                  size={BUTTON_SIZE.WITH_ICON}
                  onClick={setSuggestedFilters}
                  leadingIcon={<img className={styles.magicIcon} src={magic} />}
                >
                  {t('filters.labels.showSuggested')}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      {/* {isMobile && (
        <>
          {hasFiltersActive ? (
            <span
              className={styles.removeFilterButton}
              onClick={handleOnRemoveAllFilters}
              aria-hidden
            >
              {t('filters.labels.removeFilters')}
            </span>
          ) : (
            ''
          )}
        </>
      )} */}
    </>
  );
}
