/* eslint-disable @typescript-eslint/no-unused-vars */

import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { Helmet } from 'react-helmet';

import LibraryLoggedContent from '@components/LibraryLoggedContent';
import Header from '@components/Header';

export default function Library() {
  const { t } = useTranslation('library');

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
        <meta name="description" content={t('descriptionPage')} />
      </Helmet>
      <Header destination={'help'} buttonText={t('header')} />
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <LibraryLoggedContent />
        </div>
      </div>
    </>
  );
}
