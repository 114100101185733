import { truncate } from '@utils/index';
import * as React from 'react';
import styles from './styles.module.scss';

interface IProps {
  leadingIcon?: React.ReactNode;
  isActive?: boolean;
  id: string;
  label: string;
  disabled?: boolean;
  range?: any;
  isOnFocus?: boolean;
  onClick?: (
    e: any,
    id: string,
    { left, bottom }: { left: number; bottom: number }
  ) => void;
}
export default function FilterChip({
  isActive,
  id,
  disabled,
  isOnFocus,
  leadingIcon,
  range,
  label,
  onClick,
}: IProps) {
  const LeadingIcon = leadingIcon ? leadingIcon : null;
  const divRef = React.useRef<HTMLDivElement>();
  function handleOnClick(e: any) {
    const { left = 0, bottom = 0 }: any =
      (divRef && divRef?.current?.getBoundingClientRect()) || {};

    onClick && onClick(e, id, { left, bottom });
  }

  function formatFilterChipLabel(val: any) {
    if (typeof val === 'object') {
      const filtered = val.filter(Boolean);
      if (filtered.toString().length > 15) {
        return truncate(filtered.toString(), 15);
      }
      return filtered.toString();
    } else {
      return val;
    }
  }

  return (
    <div
      className={`${styles.container} ${isActive ? styles.isActive : ''} ${
        disabled ? styles.disabled : ''
      } ${isOnFocus ? styles.isOnFocus : ''}`}
      onClick={handleOnClick}
      aria-hidden
      ref={divRef as any}
    >
      {LeadingIcon && <div className={styles.leadingIcon}>{leadingIcon} </div>}
      {<p>{formatFilterChipLabel(label)} </p>}
    </div>
  );
}
