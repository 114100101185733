import Button, {
  BUTTON_MODE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '@components/Button';
import { getCssVariable } from '@utils/index';
import FeatherIcon from 'feather-icons-react';
import { MouseEventHandler } from 'react';

export function DeleteButton({
  onClick,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <Button
      style={{
        backgroundColor: getCssVariable('white'),
      }}
      mode={BUTTON_MODE.OUTLINE}
      type={BUTTON_TYPE.DEFAULT}
      size={BUTTON_SIZE.ONLY_ICON}
      onClick={onClick}
      leadingIcon={
        <FeatherIcon
          strokeWidth="2px"
          icon="x"
          color={'var(--neutral600)'}
          size="24"
        />
      }
    />
  );
}

export function StopButton({
  onClick,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <Button
      style={{
        margin: '0 16px',
        backgroundColor: getCssVariable('orange'),
      }}
      type={BUTTON_TYPE.RECORDING}
      size={BUTTON_SIZE.ONLY_ICON}
      onClick={onClick}
      leadingIcon={
        <FeatherIcon
          strokeWidth="1px"
          icon={'square'}
          fill={'var(--white)'}
          color={'var(--white)'}
          size="40"
        />
      }
    />
  );
}

export function CheckButton({
  onClick,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <Button
      style={{
        margin: '0 16px',
        backgroundColor: getCssVariable('blue'),
      }}
      type={BUTTON_TYPE.DONE}
      size={BUTTON_SIZE.ONLY_ICON}
      onClick={onClick}
      leadingIcon={
        <FeatherIcon
          strokeWidth="1px"
          icon={'check'}
          fill={'transparent'}
          color={'var(--white)'}
          size="40"
        />
      }
    />
  );
}

export function StartButton({
  onClick,
  isStarting,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
  isStarting: boolean;
}) {
  return (
    <Button
      showCounter={isStarting}
      disabled={isStarting}
      style={{
        backgroundColor: !isStarting
          ? getCssVariable('orange')
          : getCssVariable('neutral100'),
        margin: '0 16px',
        userSelect: 'none',
      }}
      type={BUTTON_TYPE.RECORDING}
      size={BUTTON_SIZE.ONLY_ICON}
      onClick={onClick}
      leadingIcon={
        <FeatherIcon
          strokeWidth="1px"
          icon="mic"
          color={'var(--white)'}
          size="40"
        />
      }
    />
  );
}

export function PauseButton({
  onClick,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <Button
      mode={BUTTON_MODE.OUTLINE}
      size={BUTTON_SIZE.ONLY_ICON}
      type={BUTTON_TYPE.DEFAULT}
      onClick={onClick}
      leadingIcon={
        <FeatherIcon
          strokeWidth="2px"
          icon={'pause'}
          color={'var(--neutral600)'}
          size="24"
        />
      }
    />
  );
}

export function ResumeButton({
  onClick,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <Button
      mode={BUTTON_MODE.OUTLINE}
      size={BUTTON_SIZE.ONLY_ICON}
      type={BUTTON_TYPE.DEFAULT}
      onClick={onClick}
      leadingIcon={
        <FeatherIcon
          strokeWidth="2px"
          icon={'play'}
          color={'var(--neutral600)'}
          size="24"
        />
      }
    />
  );
}

export function DownloadButton({
  onClick,
}: {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <Button
      mode={BUTTON_MODE.OUTLINE}
      size={BUTTON_SIZE.ONLY_ICON}
      type={BUTTON_TYPE.DEFAULT}
      onClick={onClick}
      leadingIcon={
        <FeatherIcon
          strokeWidth="2px"
          icon={'download'}
          color={'var(--neutral600)'}
          size="24"
        />
      }
    />
  );
}
