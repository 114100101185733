import React from 'react';
import styles from './styles.module.scss';

type Props = {
  voiceModelStep: number;
  totalStep: number;
};
export const ProgressPageBar = ({ voiceModelStep, totalStep }: Props) => {
  const calculatePercentage = React.useCallback(
    (step: number) => {
      return (step * 100) / totalStep;
    },
    [totalStep]
  );

  const progressStyle = {
    width: calculatePercentage(voiceModelStep as number) + '%',
  };

  return (
    <div className={styles.progressBar}>
      <div
        style={{ ...progressStyle }}
        className={styles.insideProgressBar}
      ></div>
    </div>
  );
};
