import React from 'react';
import ReactDOM from 'react-dom/client';
import '@styles/main.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebaseConfig from '@services/firebaseConfig';
import { FirebaseAppProvider } from 'reactfire';

import { BrowserRouter as Router } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID as string,
};

TagManager.initialize(tagManagerArgs);

root.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <Router>
      <App />
    </Router>
  </FirebaseAppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
