import Header from '@components/Header';
import LayoutWrapper from '@components/LayoutWrapper';
import Loader from '@components/Loader';

import SplitLayout from '@components/SplitLayout';
import { ROUTES } from '@routes/routes';
import { doc, getDoc } from 'firebase/firestore';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUser, useFirestore } from 'reactfire';
import OnBoardingContentInternalTool from '@components/OnBoardingContentInternalTool';

import OnBoardingRightSideInternalTool from '@components/OnBoardingRightSideInternalTool';
import { Helmet } from 'react-helmet';
export default function OnBoarding() {
  const { t } = useTranslation('onBoarding');
  const [currentLanguage, setCurrentLanguage] = React.useState('');
  const [isChangingRecording, setIsChangingRecording] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentRecording, setCurrentRecording] = React.useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const db = useFirestore();
  const { data: user } = useUser();

  const getUserData = React.useCallback(async () => {
    if (user) {
      const userRefPublic = doc(db, 'users', user?.uid);
      const docSnapPublic = await getDoc(userRefPublic);

      const userRefPrivate = doc(db, `users/${user.uid}/private`, 'donor');
      const docSnapPrivate = await getDoc(userRefPrivate);

      if (docSnapPublic.exists() && docSnapPrivate.exists()) {
        const userDataPublic = docSnapPublic.data();
        const userDataPrivate = docSnapPrivate.data();
        const currentStep = userDataPrivate?.onBoardingStep;
        const currentLanguage = userDataPublic?.language;
        const isChangingRecordingState = userDataPrivate?.isChangingRecording;
        const currentRecordingUrl = userDataPrivate?.URL_recording;
        setIsChangingRecording(isChangingRecordingState);
        setCurrentLanguage(currentLanguage);
        setCurrentRecording(currentRecordingUrl);

        if (currentStep === 'done' || currentStep === 'submit') {
          navigate(`${ROUTES.BASE}`);
        } else {
          setIsLoading(false);
        }
      } else {
        console.log('No such document!');
      }
    }
  }, [user, db, navigate]);

  React.useEffect(() => {
    getUserData();
  }, [getUserData]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>{t('externalTool.pageTitle')}</title>
        <meta
          name="description"
          content={t('chooseTool.externalTool.descriptionPage')}
        />
      </Helmet>
      <Header destination={'help'} buttonText={t('externalTool.header')} />

      <LayoutWrapper>
        <SplitLayout
          left={
            <OnBoardingContentInternalTool
              isChangingRecording={isChangingRecording}
              currentLanguage={currentLanguage}
            />
          }
          right={<OnBoardingRightSideInternalTool language={currentLanguage} />}
        />
      </LayoutWrapper>
    </>
  );
}
