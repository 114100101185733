/* eslint-disable @typescript-eslint/no-unused-vars */
import { renderScriptOnboardingForValidation } from '@components/ScriptInternalTool';
import Spinner from '@components/Spinner';
import VoiceModelRecorderControls, {
  VoiceRecorderState,
} from '@components/VoiceModelRecorderControls';
import VoiceProblemModal from '@components/VoiceProblemModal';
import { ROUTES } from '@routes/routes';
import { validateAudioFile, VALIDATION_AUDIO_RESPONSE } from '@services/api';
import { format } from 'date-fns';
import { getApp } from 'firebase/app';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import {
  getDownloadURL,
  getStorage,
  ref,
  updateMetadata,
  uploadBytesResumable,
} from 'firebase/storage';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { useFirestore, useUser } from 'reactfire';
import styles from './styles.module.scss';
import useBrowserDetection from '@hooks/useBrowserDetection';

type IProps = {
  isChangingRecording?: boolean;
  currentLanguage: string;
};

export default function OnBoardingContentInternalTool({
  isChangingRecording,
  currentLanguage,
}: IProps) {
  const navigate = useNavigate();
  const firebaseApp = getApp();
  const db = useFirestore();
  const { data: user } = useUser();
  const [userLang, setUserLang] = React.useState();
  const [uploadProgress, setUploadProgress] = React.useState<number>();
  const [recorderState, setRecorderState] =
    useState<VoiceRecorderState>('inactive');
  const { t } = useTranslation('onBoarding');
  const browser = useBrowserDetection();
  const storage = getStorage(
    firebaseApp,
    process.env.REACT_APP_FIREBASE_BUCKET
  );

  const getUserData = React.useCallback(async () => {
    if (user) {
      const userRef = doc(db, `users/${user.uid}/private`, 'donor');
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        const currentStep = userData?.onBoardingStep;
        const currentUserLang = userData?.language;
        setUserLang(currentUserLang);
        if (currentStep === 'done' || currentStep === 'submit') {
          navigate(`${ROUTES.BASE}`);
        }
      } else {
        console.log('No such document!');
      }
    }
  }, [user, db, navigate]);

  React.useEffect(() => {
    getUserData();
  }, [getUserData]);

  const updateUserData = React.useCallback(
    async (step: string) => {
      if (user) {
        await setDoc(
          doc(db, 'users', user.uid),
          {
            browserDetailsOnboarding: browser,
          },
          { merge: true }
        );
        await setDoc(
          doc(db, `users/${user.uid}/private`, 'donor'),
          {
            onBoardingStep: step,
            isChangingRecording: false,
          },
          { merge: true }
        );
      }
    },
    [db, user, browser]
  );

  const storageRef = ref(storage, `users/${user?.uid}/your-recording.wav`);

  const text = renderScriptOnboardingForValidation(
    currentLanguage,
    user?.displayName?.split(' ')[0] as string
  );

  const handleConfirmRecording = async (blob: Blob) => {
    console.log('recording confirmed');
    if (recorderState === 'recorded') {
      const formData = new FormData();
      formData.append('language', currentLanguage);
      formData.append('text', text.toString());
      formData.append('file', blob);
      formData.append('step', 'signup');

      // SEND FILE TO ENDPOINT AND VALIDATE
      try {
        const res = await validateAudioFile(formData);
        const validationResult = 'accept';

        if (validationResult === VALIDATION_AUDIO_RESPONSE.ACCEPT) {
          // IF SUCCESS
          const uploadTask = uploadBytesResumable(storageRef, blob);
          uploadTask.on(
            'state_changed',
            (snapshot: any) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setUploadProgress(progress);
            },
            () => {
              setRecorderState('error');
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then(
                async (downloadURL) => {
                  const lastEqual = downloadURL.lastIndexOf('=');
                  const uuid = downloadURL.substring(lastEqual + 1);

                  const metadata = {
                    customMetadata: {
                      uuid: uuid,
                      userId: user?.uid as string,
                    },
                  };
                  await updateMetadata(uploadTask.snapshot.ref, metadata);

                  await updateDoc(doc(db, 'users', user?.uid as any), {
                    URL_recording: downloadURL,
                    inserted: new Date().toISOString(),
                    browserDetailsOnboarding: browser,
                  });

                  await setDoc(
                    doc(db, `users/${user?.uid}/private`, 'donor'),
                    {
                      URL_recording: downloadURL,
                    },
                    { merge: true }
                  );

                  let userData;
                  if (user) {
                    const usersRefPublic = doc(db, 'users', user?.uid);
                    const docSnapPublic = await getDoc(usersRefPublic);
                    userData = docSnapPublic?.data();
                  }

                  const date = format(new Date(), 'yyyy-MM-dd--HH:mm:ss');
                  if (!isChangingRecording) {
                    await setDoc(
                      doc(db, `mail/${user?.uid}/notifications`, date as any),
                      {
                        from: process.env.REACT_APP_FIREBASE_EMAIL_SENDER,
                        replyTo: user?.email,
                        to: process.env.REACT_APP_FIREBASE_EMAIL_RECIPIENT,
                        message: {
                          subject: `New DONOR ${userData?.language} with name ${user?.displayName}`,
                          text: `User ${user?.displayName} with ID ${user?.uid} and email ${user?.email}, has just signed up as DONOR.\n Voice sample here: ${downloadURL} `,
                        },
                      }
                    );
                  }
                }
              );
              updateUserData('submit');
              navigate(ROUTES.THANK_YOU);
            }
          );
        } else {
          // IF NOT SUCCESS
          setRecorderState('error');
        }
      } catch (error) {
        console.log(error, 'something went wrong');
        setRecorderState('error');
        setTimeout(() => {
          setRecorderState('inactive');
        }, 2000);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.recorderContainer}>
        <Modal
          isOpen={recorderState === 'error'}
          onRequestClose={() => setRecorderState('inactive')}
          overlayClassName={styles.overlay}
          className={styles.modal}
        >
          <VoiceProblemModal
            lang={userLang ? userLang : 'en-EN'}
            closeModal={() => setRecorderState('inactive')}
          />
        </Modal>
        <VoiceModelRecorderControls
          recorderState={recorderState}
          setRecorderState={setRecorderState}
          onConfirmedRecording={handleConfirmRecording}
        />
        {recorderState === 'confirmed' && (
          <div className={styles.spinnerContainer}>
            <>
              <p>
                {uploadProgress == null
                  ? t('voiceProblemModal.isAnalyzing')
                  : t('internalTool.onSubmit', {
                      progress: uploadProgress.toFixed() + '%',
                    })}
              </p>
              <Spinner isMid />{' '}
            </>
          </div>
        )}
        {recorderState === 'error' && (
          <div className={styles.spinnerContainer}>
            <p className={styles.error}>{t('voiceProblemModal.errorsState')}</p>
          </div>
        )}
      </div>
    </div>
  );
}
