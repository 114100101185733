import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@routes/routes';
import { Trans, useTranslation } from 'react-i18next';
import Button, {
  BUTTON_MODE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from '@components/Button';
import FeatherIcon from 'feather-icons-react';
import { getCssVariable } from '@utils/index';
import SuccessMessageOnBoarding from '@components/SuccessMessageOnBoarding';
import { useFirestore, useUser } from 'reactfire';
import { doc, updateDoc } from 'firebase/firestore';
import styles from './styles.module.scss';

type Props = {
  lang: string;
};
export default function VoiceModel({ lang }: Props) {
  const { t } = useTranslation(['modelRecording']);
  const navigate = useNavigate();
  const db = useFirestore();
  const { data: user } = useUser();

  const goToRecordVoiceModel = React.useCallback(async () => {
    if (user) {
      await updateDoc(doc(db, 'users', user.uid), {
        recordModelStep: 1,
      });

      navigate(ROUTES.RECORD_MODEL);
    }
  }, [navigate, user, db]);

  function createDownloadLinkPDF(
    prefix: string,
    language: string,
    nameFile: string,
    pageNumber?: number
  ) {
    const splitLang = language ? language.split('-')[0] : 'en';
    return `/${prefix}-${nameFile}-${splitLang}.pdf${
      pageNumber ? '#page=' + pageNumber.toString() : ''
    }`;
  }

  return (
    <div className={styles.container}>
      <SuccessMessageOnBoarding
        title={t('modelRecording:landing.title', {
          user: user?.displayName?.split(' ')[0],
        })}
        message={
          <p>
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <Trans
                defaults={t('modelRecording:landing.description')}
                components={{
                  RecordingGuidelines: (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={createDownloadLinkPDF(
                        'Recording',
                        lang,
                        'guidelines'
                      )}
                    ></a>
                  ),
                }}
              />
            }
          </p>
        }
        leadingIcon={
          <Button
            style={{
              backgroundColor: getCssVariable('orange-light'),
              cursor: 'default',
            }}
            type={BUTTON_TYPE.RECORDING}
            size={BUTTON_SIZE.ONLY_ICON}
            leadingIcon={
              <FeatherIcon
                strokeWidth="1px"
                icon="layers"
                color={'var(--orange)'}
                size="40"
              />
            }
          ></Button>
        }
      >
        <div className={styles.list}>
          <div className={`${styles.item} ${styles.item1}`}>
            <div className={styles.icon}>
              <FeatherIcon
                strokeWidth="1px"
                icon="clock"
                color={'var(--neutral400)'}
                size="20"
              />
            </div>
            <div className={styles.content}>
              <p className={styles.title}>
                {t('modelRecording:landing.list.item1.title')}
              </p>
              <span className="small">
                {t('modelRecording:landing.list.item1.description')}
              </span>
            </div>
          </div>
          <div className={`${styles.item} ${styles.item2}`}>
            <div className={styles.icon}>
              <FeatherIcon
                strokeWidth="1px"
                icon="mic"
                color={'var(--neutral400)'}
                size="20"
              />
            </div>
            <div className={styles.content}>
              <p className={styles.title}>
                {t('modelRecording:landing.list.item2.title')}
              </p>
              <span className="small">
                {t('modelRecording:landing.list.item2.description')}
              </span>
            </div>
          </div>
        </div>
        <Button
          onClick={goToRecordVoiceModel}
          type={BUTTON_TYPE.PRIMARY}
          mode={BUTTON_MODE.BASIC}
          fullWidth
        >
          {t('modelRecording:landing.cta')}
        </Button>
      </SuccessMessageOnBoarding>
    </div>
  );
}
