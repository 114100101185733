import React from 'react';
// import { useTranslation } from 'react-i18next';

const ProfileImageContext = React.createContext<any>(null);

interface IProps {
  children: React.ReactElement | React.ReactElement[];
}
const ProfileImageProvider = ({ children }: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [imageURLProfile, setImageURLProfile] = React.useState(null);
  // const { i18n } = useTranslation();

  // const [initialLang, setInitialLang] = React.useState<string>();

  return (
    <ProfileImageContext.Provider
      value={{ imageURLProfile, setImageURLProfile } as any}
    >
      {children}
    </ProfileImageContext.Provider>
  );
};

export { ProfileImageProvider, ProfileImageContext };
