/* eslint-disable react/jsx-key */
import styles from './styles.module.scss';
interface IProps {
  checked?: boolean;
  children: React.ReactElement | React.ReactElement[];
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  forLabel: string;
}
export default function Radio({
  forLabel,
  checked,
  children,
  onChange,
}: IProps) {
  return (
    <label
      htmlFor={forLabel}
      className={`${styles.control} ${styles.controlCheckbox}`}
    >
      {children}
      <input
        id={forLabel}
        type="radio"
        name="gender"
        onChange={onChange}
        checked={checked}
      />
      <div className={styles.controlIndicator}></div>
    </label>
  );
}
