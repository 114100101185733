/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import Button, { BUTTON_MODE, BUTTON_TYPE } from '@components/Button';
import Modal from 'react-modal';
import DeleteUserPrompt from '@components/DeleteUserPrompt';

type Props = {
  user: any;
  db: any;
  auth: any;
  userLang: string;
  userType: string;
  requestedDeletionAccount: boolean;
};

export default function DeleteAccount({
  requestedDeletionAccount = false,
  user,
  db,
  userType,
  userLang,
  auth,
}: Props) {
  const [showDeletePrompt, setShowDeletePrompt] = React.useState(false);
  const [deletionAccount, setDeletionAccount] = React.useState(
    requestedDeletionAccount
  );
  const { t } = useTranslation('dashboard');

  function closeModal() {
    setShowDeletePrompt(false);
  }
  function openModal() {
    setShowDeletePrompt(true);
  }
  React.useEffect(() => {
    setDeletionAccount(requestedDeletionAccount);
  }, [requestedDeletionAccount]);

  Modal.setAppElement('div');

  return (
    <>
      <Modal
        isOpen={showDeletePrompt}
        onRequestClose={closeModal}
        overlayClassName={styles.overlay}
        className={styles.modal}
      >
        <DeleteUserPrompt
          db={db}
          user={user}
          currentAuthUser={auth?.currentUser}
          closeModal={closeModal}
          setDeletion={setDeletionAccount}
          userLang={userLang}
          userType={userType}
        />
      </Modal>

      <div className={styles.container}>
        {deletionAccount ? (
          <p style={{ marginBottom: '0' }}>
            {t('deleteAccount.requestedDeletion')}
          </p>
        ) : (
          <>
            <p>{t('deleteAccount.subTitle')}</p>
            <Button
              type={BUTTON_TYPE.ERROR}
              onClick={openModal}
              mode={BUTTON_MODE.OUTLINE}
            >
              {t('deleteAccount.button')}
            </Button>
          </>
        )}
      </div>
    </>
  );
}
