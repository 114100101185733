/* eslint-disable @typescript-eslint/no-unused-vars */
import Header from '@components/Header';
import LayoutWrapper from '@components/LayoutWrapper';
import SignUpRightSide from '@components/SignUpRightSide';
import { User } from '@components/AutodonateForm';
import SplitLayout from '@components/SplitLayout';
import { ROUTES } from '@routes/routes';
import image from '@assets/img/autodonate-pic.png';

import * as React from 'react';
// import { sample } from 'lodash';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import {
  getAuth,
  updateProfile,
  createUserWithEmailAndPassword,
} from 'firebase/auth';

import { useTranslation } from 'react-i18next';
import { useFirestore, useUser } from 'reactfire';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import AutodonateForm from '@components/AutodonateForm';
import { format } from 'date-fns';

export enum SIGNUP_ERROR_TYPE {
  EMAIL = 'email',
  GENERIC = 'generic',
  PASSWORD = 'password',
}

export default function Autodonate() {
  const navigate = useNavigate();
  const auth = getAuth();

  const { t } = useTranslation('signUp');
  const [error, setError] = React.useState<{ type: string; message: string }>({
    type: '',
    message: '',
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const db = useFirestore();
  const [step, setStep] = React.useState(0);
  // const [randomProfileImages, setRandomProfileImages] = React.useState([]);
  const [userType, setUserType] = React.useState('');

  // const returnUrl = `${window.location.protocol}//${window.location.host}/sign-in`;
  const { data: user } = useUser();

  React.useEffect(() => {
    async function getUser() {
      if (user) {
        setIsLoading(true);
        const userRefPublic = doc(db, 'users', user?.uid);
        const docSnapPublic = await getDoc(userRefPublic);
        const userDataPublic = docSnapPublic.data();
        const userType = userDataPublic?.userType;
        setUserType(userType);
        if (userType === 'both' || userType === 'donor') {
          navigate(`${ROUTES.BASE}`);
        } else {
          navigate(`${ROUTES.FINISH_REGISTRATION}`);
        }
      }
    }
    getUser();
  }, [db, navigate, user]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSignUp = React.useCallback(
    async ({
      firstName,
      lastName,
      email,
      password,
      birthday,
      gender,
      whyNeedVoice,
      aacModel,
      otherModel,
      therapist,
      phone,
      language,
      country,
      agreeTerms,
      avatar_photo,
      agreeTerms2,
    }: User) => {
      try {
        const yearBirth = birthday?.split('-')[0];
        const age = yearBirth && new Date().getFullYear() - +yearBirth;

        const { user } = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );

        await updateProfile(user, {
          displayName: `${firstName} ${lastName}`,
        });

        await setDoc(
          doc(db, 'users', user.uid),
          {
            firstname: firstName,
            language,
            describeYourself: '',
            userType: 'donor',
            id: user.uid,
            avatar_photo,
            URL_recording: 'placeholderURL',
            voiceType:
              Number(age) >= 51
                ? 'senior'
                : Number(age) >= 26
                ? 'middle'
                : 'young',
            gender,
            isAutodonate: true,
            inserted: new Date().toISOString(),
            isHiddenFromLibrary: true,
          },
          { merge: true }
        );

        await setDoc(doc(db, `users/${user.uid}/private`, 'donor'), {
          lastname: lastName,
          firstname: firstName,
          birthday,
          age,
          email,
          agreeTerms,
          agreeTerms2,
          country,
          gender,
          language,
          onBoardingStep: 'submit',
          whyNeedVoice,
          favorites: [],
          requestedVoices: [],
          currentRequestedVoice: '',
          aacModel: aacModel === 'other' ? otherModel : aacModel,
          therapist,
          phone,
          isAutodonate: true,
          isChangingRecording: false,
        });

        const date = format(new Date(), 'yyyy-MM-dd--HH:mm:ss');

        await setDoc(doc(db, `mail/${user?.uid}/notifications`, date as any), {
          from: process.env.REACT_APP_FIREBASE_EMAIL_SENDER,
          replyTo: user?.email,
          cc: process.env.REACT_APP_FIREBASE_EMAIL_CC,
          to: process.env.REACT_APP_FIREBASE_EMAIL_RECIPIENT,
          message: {
            subject: `New AUTO DONOR with name ${user?.displayName}`,
            text: `User ${user?.displayName} with ID ${user?.uid} and email ${user?.email}, has just signed up as AUTO DONOR.\n Patient info: \n First name: ${firstName} \n Last name: ${lastName} \n Gender: ${gender}  \n Aac Model: ${aacModel} \n Therapist: ${therapist}  \n Why need voice: ${whyNeedVoice} \n Age: ${age} \n Phone: ${phone}  \n Birthday: ${birthday} \n Country: ${country} \n Email: ${email}. \n Click here to enable the user to record the voice model: ${process.env.REACT_APP_ENABLE_RECORDING_FN}${user?.uid} \n The link activate/enable the button to start recording the voice model in his dashboard.`,
          },
        });
        navigate(`${ROUTES.THANK_YOU}`, { state: { autodonate: true } });
      } catch (err: any) {
        console.log(err, err.code, err.message, 'ERROR--------------------');
        if (err instanceof Error) {
          setError({ type: SIGNUP_ERROR_TYPE.GENERIC, message: err?.message });
        }
        if (err?.code === 'auth/email-already-in-use') {
          setError({
            type: SIGNUP_ERROR_TYPE.EMAIL,
            message: t('form.errorsState.general.emailAlreadyInUse'),
          });
          setStep(0);
          setTimeout(() => {
            setError({ type: '', message: '' });
            navigate(ROUTES.SIGN_IN);
          }, 5000);
        } else if (err?.code === 'auth/invalid-email') {
          setError({
            type: SIGNUP_ERROR_TYPE.EMAIL,
            message: t('form.errorsState.general.invalidEmail'),
          });
          setStep(0);
          setTimeout(() => {
            setError({ type: '', message: '' });
          }, 2000);
        } else if (err.code === 'auth/weak-password') {
          setStep(0);
          setError({
            type: SIGNUP_ERROR_TYPE.PASSWORD,
            message: t('form.errorsState.general.weakPassword'),
          });
          setTimeout(() => {
            setError({ type: '', message: '' });
          }, 2000);
        } else {
          setError(err?.message);
          setTimeout(() => {
            setError({ type: '', message: '' });
          }, 2000);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [auth, db, navigate, t]
  );

  function onSubmitForm(user: User): void {
    setIsLoading(true);
    onSignUp(user);
  }

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
        <meta name="description" content={t('descriptionPage')} />
      </Helmet>
      <Header destination={ROUTES.SIGN_IN} buttonText={t('header.signIn')} />

      <LayoutWrapper>
        <SplitLayout
          userType={userType ?? ''}
          left={
            <>
              <AutodonateForm
                onSubmit={onSubmitForm}
                isLoading={isLoading}
                step={step}
                setStep={setStep}
                error={error ?? {}}
              />
              {error && error?.message && error?.message.length ? (
                <p className={styles.errorMessage}>{error?.message}</p>
              ) : null}
            </>
          }
          right={<SignUpRightSide imageUrl={image} />}
        />
      </LayoutWrapper>
    </>
  );
}
